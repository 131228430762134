// models
import * as MZ from "./MZ";
//=================================================
const KPIs = {
  "TotalRevenue/RevenueAnalysis": {
    keys: ["total_revenue", "total_budget", "total_forecast"],
    actual: ({ total_revenue }) => total_revenue ?? NaN,
    budget: ({ total_budget }) => total_budget ?? NaN,
    forecast: ({ total_forecast }) => total_forecast ?? NaN,
    round: 0,
    multiply: true,
    cKeys: ["total_revenue", "total_budget", "total_forecast"],
  },
  "TotalRevenue/AverageCheck": {
    keys: ["total_revenue", "total_budget", "total_forecast", "covers"],
    actual: ({ total_revenue, covers }) => (total_revenue ?? NaN) / (covers ?? NaN),
    budget: ({ total_budget, covers }) => (total_budget ?? NaN) / (covers ?? NaN),
    forecast: ({ total_forecast, covers }) => (total_forecast ?? NaN) / (covers ?? NaN),
    round: 2,
    multiply: false,
    cKeys: ["total_revenue", "total_budget", "total_forecast"],
  },
  "TotalRevenue/RevenuePerSeat": {
    keys: ["total_revenue", "total_budget", "total_forecast", "stat_seats"],
    actual: ({ total_revenue, stat_seats }) =>
      (total_revenue ?? NaN) / (stat_seats ?? NaN),
    budget: ({ total_budget, stat_seats }) => (total_budget ?? NaN) / (stat_seats ?? NaN),
    forecast: ({ total_forecast, stat_seats }) =>
      (total_forecast ?? NaN) / (stat_seats ?? NaN),
    round: 2,
    multiply: false,
    cKeys: ["total_revenue", "total_budget", "total_forecast"],
  },
  "TotalRevenue/RevenuePerHour": {
    keys: [
      "total_revenue",
      "total_budget",
      "total_forecast",
      "stat_work_hours",
    ],
    actual: ({ total_revenue, stat_work_hours }) =>
      (total_revenue ?? NaN) / (stat_work_hours ?? NaN),
    budget: ({ total_budget, stat_work_hours }) =>
      (total_budget ?? NaN) / (stat_work_hours ?? NaN),
    forecast: ({ total_forecast, stat_work_hours }) =>
      (total_forecast ?? NaN) / (stat_work_hours ?? NaN),
    round: 2,
    multiply: false,
    cKeys: ["total_revenue", "total_budget", "total_forecast"],
  },
  "TotalRevenue/RevenuePerSeatPerHour": {
    keys: [
      "total_revenue",
      "total_budget",
      "total_forecast",
      "stat_seats",
      "stat_work_hours",
    ],
    actual: ({ total_revenue, stat_seats, stat_work_hours }) =>
      (total_revenue ?? NaN) / (stat_seats ?? NaN) / (stat_work_hours ?? NaN),
    budget: ({ total_budget, stat_seats, stat_work_hours }) =>
      (total_budget ?? NaN) / (stat_seats ?? NaN) / (stat_work_hours ?? NaN),
    forecast: ({ total_forecast, stat_seats, stat_work_hours }) =>
      (total_forecast ?? NaN) / (stat_seats ?? NaN) / (stat_work_hours ?? NaN),
    round: 2,
    multiply: false,
    cKeys: ["total_revenue", "total_budget", "total_forecast"],
  },
  "TotalRevenue/RevenuePerSqm": {
    keys: ["total_revenue", "total_budget", "total_forecast", "stat_size"],
    actual: ({ total_revenue, stat_size }) => (total_revenue ?? NaN) / (stat_size ?? NaN),
    budget: ({ total_budget, stat_size }) => (total_budget ?? NaN) / (stat_size ?? NaN),
    forecast: ({ total_forecast, stat_size }) =>
      (total_forecast ?? NaN) / (stat_size ?? NaN),
    round: 2,
    multiply: false,
    cKeys: ["total_revenue", "total_budget", "total_forecast"],
  },
  "FoodRevenue/RevenueAnalysis": {
    keys: ["food_revenue", "food_budget", "food_forecast"],
    actual: ({ food_revenue }) => (food_revenue ?? NaN),
    budget: ({ food_budget }) => (food_budget ?? NaN),
    forecast: ({ food_forecast }) => (food_forecast ?? NaN),
    round: 0,
    multiply: true,
    cKeys: ["food_revenue", "food_budget", "food_forecast"],
  },
  "FoodRevenue/RatioAnalysis": {
    keys: [
      "total_revenue",
      "total_budget",
      "total_forecast",
      "food_revenue",
      "food_budget",
      "food_forecast",
    ],
    actual: ({ total_revenue, food_revenue }) =>
      (food_revenue ?? NaN) / (total_revenue ?? NaN),
    budget: ({ total_budget, food_budget }) =>
      (food_budget ?? NaN) / (total_budget ?? NaN),
    forecast: ({ total_forecast, food_forecast }) =>
      (food_forecast ?? NaN) / (total_forecast ?? NaN),
    round: 2,
    multiply: false,
    cKeys: [
      "total_revenue",
      "total_budget",
      "total_forecast",
      "food_revenue",
      "food_budget",
      "food_forecast",
    ]
  },
  "BeverageRevenue/RevenueAnalysis": {
    keys: ["beverage_revenue", "beverage_budget", "beverage_forecast"],
    actual: ({ beverage_revenue }) => (beverage_revenue ?? NaN),
    budget: ({ beverage_budget }) => (beverage_budget ?? NaN),
    forecast: ({ beverage_forecast }) => (beverage_forecast ?? NaN),
    round: 0,
    multiply: true,
    cKeys: ["beverage_revenue", "beverage_budget", "beverage_forecast"],
  },
  "BeverageRevenue/RatioAnalysis": {
    keys: [
      "total_revenue",
      "total_budget",
      "total_forecast",
      "beverage_revenue",
      "beverage_budget",
      "beverage_forecast",
    ],
    actual: ({ total_revenue, beverage_revenue }) =>
      (beverage_revenue ?? NaN) / (total_revenue ?? NaN),
    budget: ({ total_budget, beverage_budget }) =>
      (beverage_budget ?? NaN) / (total_budget ?? NaN),
    forecast: ({ total_forecast, beverage_forecast }) =>
      (beverage_forecast ?? NaN) / (total_forecast ?? NaN),
    round: 2,
    multiply: false,
    cKeys: [
      "total_revenue",
      "total_budget",
      "total_forecast",
      "beverage_revenue",
      "beverage_budget",
      "beverage_forecast",],
  },
  "OtherRevenue/RevenueAnalysis": {
    keys: ["other_revenue", "other_budget", "other_forecast"],
    actual: ({ other_revenue }) => (other_revenue ?? NaN),
    budget: ({ other_budget }) => (other_budget ?? NaN),
    forecast: ({ other_forecast }) => (other_forecast ?? NaN),
    round: 0,
    multiply: true,
    cKeys: ["other_revenue", "other_budget", "other_forecast"],
  },
  "OtherRevenue/RatioAnalysis": {
    keys: [
      "total_revenue",
      "total_budget",
      "total_forecast",
      "other_revenue",
      "other_budget",
      "other_forecast",
    ],
    actual: ({ total_revenue, other_revenue }) =>
      (other_revenue ?? NaN) / (total_revenue ?? NaN),
    budget: ({ total_budget, other_budget }) =>
      (other_budget ?? NaN) / (total_budget ?? NaN),
    forecast: ({ total_forecast, other_forecast }) =>
      (other_forecast ?? NaN) / (total_forecast ?? NaN),
    round: 2,
    multiply: false,
    cKeys: [
      "total_revenue",
      "total_budget",
      "total_forecast",
      "other_revenue",
      "other_budget",
      "other_forecast",],
  },
  "CoversRevenue/TotalCovers": {
    keys: ["covers", "covers_budget", "covers_forecast"],
    actual: ({ covers }) => (covers ?? NaN),
    budget: ({ covers_budget }) => (covers_budget ?? NaN),
    forecast: ({ covers_forecast }) => (covers_forecast ?? NaN),
    round: 0,
    multiply: true,
    cKeys: []
  },
};
export class MyReportsM {

  static processDownloadData(filterData, reports, rawData, dependants) {
    try {
      let data = {
        "filename": new MZ.DateTime().getFormated(),
        "width": [
          12,
          12,
          10,
          10,
          10,
          10,
          10,
          10,
          10,
          10,
          10,
        ],
        "sheets": [
        ]
      }

      for (let report of reports) {
        let r = MyReportsM.processData(filterData, report, rawData, dependants);
        if (r.success !== true) return r;
        let pData = r.data;
        let rows = [];
        // avg weekdays
        if (pData.wdAvg === true) {
          // headers
          rows.push(_row([
            _cell(MZ.T("weekdays-average"), 2, 2),
            _cell(""),
            _cell(MZ.T("current"), 2, 1),
            _cell(""),
            _cell(MZ.T("comparison"), 2, 1),
            _cell(""),
            _cell(MZ.T("chg"), 2, 1),
            _cell(""),
            _cell(MZ.T("index"), 1, 2),
            _cell(MZ.T("chg"), 1, 2),
            _cell(MZ.T("rank"), 1, 2),
          ], true))
          rows.push(_row([
            _cell(""),
            _cell(""),
            _cell(MZ.T("properties")),
            _cell(MZ.T("benchmark")),
            _cell(MZ.T("properties")),
            _cell(MZ.T("benchmark")),
            _cell(MZ.T("properties")),
            _cell(MZ.T("benchmark")),
          ], true))
          // data
          pData.detailsWdAvg = MZ.Arr.sort(
            MZ.Arr.objToArr(pData.detailsWdAvg),
            false,
            "order"
          );
          for (let obj of pData.detailsWdAvg) {
            rows.push(_row([
              _cell(`${MZ.T(
                MZ.DateTime.weekdayNoText(obj.num, false).toLowerCase()
              )} ${MZ.T("average")}`, 2),
              _cell(""),
              _cell(obj.actual),
              _cell(obj.bActual),
              _cell(obj.cActual),
              _cell(obj.cbActual),
              _cell(obj.tChg),
              _cell(obj.bChg),
              _cell(obj.index),
              _cell(obj.chg),
              _cell(`${obj.rank} / ${obj.rankCount}`),
            ]));
          }
          // total
          let total = pData.detailsWdAvgTotal;
          rows.push(_row([
            _cell(`${MZ.T('total')}`, 2),
            _cell(""),
            _cell(total.actual),
            _cell(total.bActual),
            _cell(total.cActual),
            _cell(total.cbActual),
            _cell(total.tChg),
            _cell(total.bChg),
            _cell(total.index),
            _cell(total.chg),
            _cell(`${total.rank} / ${total.rankCount}`),
          ]));
          // sep
          rows.push(_row([_cell("", 11)]));
        }
        // ttl weekdays
        if (pData.wdTtl === true) {
          // headers
          rows.push(_row([
            _cell(MZ.T("weekdays-total"), 2, 2),
            _cell(""),
            _cell(MZ.T("current"), 2, 1),
            _cell(""),
            _cell(MZ.T("comparison"), 2, 1),
            _cell(""),
            _cell(MZ.T("chg"), 2, 1),
            _cell(""),
            _cell(MZ.T("index"), 1, 2),
            _cell(MZ.T("chg"), 1, 2),
            _cell(MZ.T("rank"), 1, 2),
          ], true))
          rows.push(_row([
            _cell(""),
            _cell(""),
            _cell(MZ.T("properties")),
            _cell(MZ.T("benchmark")),
            _cell(MZ.T("properties")),
            _cell(MZ.T("benchmark")),
            _cell(MZ.T("properties")),
            _cell(MZ.T("benchmark")),
          ], true))
          // data
          pData.detailsWdTtl = MZ.Arr.sort(
            MZ.Arr.objToArr(pData.detailsWdTtl),
            false,
            "order"
          );
          for (let obj of pData.detailsWdTtl) {
            rows.push(_row([
              _cell(`${MZ.T(
                MZ.DateTime.weekdayNoText(obj.num, false).toLowerCase()
              )} ${MZ.T("total")}`, 2),
              _cell(""),
              _cell(obj.actual),
              _cell(obj.bActual),
              _cell(obj.cActual),
              _cell(obj.cbActual),
              _cell(obj.tChg),
              _cell(obj.bChg),
              _cell(obj.index),
              _cell(obj.chg),
              _cell(`${obj.rank} / ${obj.rankCount}`),
            ]));
          }
          // total
          let total = pData.detailsTotal;
          rows.push(_row([
            _cell(`${MZ.T('total')}`, 2),
            _cell(""),
            _cell(total.actual),
            _cell(total.bActual),
            _cell(total.cActual),
            _cell(total.cbActual),
            _cell(total.tChg),
            _cell(total.bChg),
            _cell(total.index),
            _cell(total.chg),
            _cell(`${total.rank} / ${total.rankCount}`),
          ]));
          // sep
          rows.push(_row([_cell("", 11)]));
        }
        // details
        // headers
        rows.push(_row([
          _cell(MZ.T("dates"), 2, 2),
          _cell(""),
          _cell(MZ.T("current"), 2, 1),
          _cell(""),
          _cell(MZ.T("comparison"), 2, 1),
          _cell(""),
          _cell(MZ.T("chg"), 2, 1),
          _cell(""),
          _cell(MZ.T("index"), 1, 2),
          _cell(MZ.T("chg"), 1, 2),
          _cell(MZ.T("rank"), 1, 2),
        ], true))
        rows.push(_row([
          _cell(""),
          _cell(""),
          _cell(MZ.T("properties")),
          _cell(MZ.T("benchmark")),
          _cell(MZ.T("properties")),
          _cell(MZ.T("benchmark")),
          _cell(MZ.T("properties")),
          _cell(MZ.T("benchmark")),
        ], true))
        // data
        for (let k in pData.milestones) {
          let ms = pData.milestones[k];
          let obj = pData.details[k];
          rows.push(_row([
            _cell(ms.date),
            _cell(ms.name),
            _cell(obj.actual),
            _cell(obj.bActual),
            _cell(obj.cActual),
            _cell(obj.cbActual),
            _cell(obj.tChg),
            _cell(obj.bChg),
            _cell(obj.index),
            _cell(obj.chg),
            _cell(`${obj.rank} / ${obj.rankCount}`),
          ]));
        }
        // total
        var total = pData.detailsTotal;
        rows.push(_row([
          _cell(`${MZ.T('total')}`, 2),
          _cell(""),
          _cell(total.actual),
          _cell(total.bActual),
          _cell(total.cActual),
          _cell(total.cbActual),
          _cell(total.tChg),
          _cell(total.bChg),
          _cell(total.index),
          _cell(total.chg),
          _cell(`${total.rank} / ${total.rankCount}`),
        ]));
        //
        data.sheets.push(_sheet(rows, MZ.T(pData.report.id.toLowerCase())))
      }
      return MZ.Success(null, data);
    }
    catch (err) {
      console.log(err)
      return MZ.Error(err)
    }
    //
    function _sheet(rows, name = false) {
      return { sheetRows: rows, sheetName: name }
    }
    function _row(cells, head = false) {
      return { rowCells: cells, head: head }
    }
    function _cell(data, colSpan = 1, rowSpan = 1, head = false) {
      return { data: data, colSpan: colSpan, rowSpan: rowSpan, head: head }
    }
  }

  // process data
  static processData(filterData, report_id, rawData, dependants, portal) {
    try {
      if (!report_id) return MZ.Error("kpi_not_choosen");
      if (!rawData) return MZ.Error("no_data");
      if (!dependants) return MZ.Error("no_dependants");
      // data
      let data = {
        id: "Ymd",
        format: "Y-m-d",
        currency: {
          name: null,
          symbol: null,
          rates: [],
        },
        report: {
          id: null,
          title: null,
          keys: null,
          cKeys: null,
          actual: null,
          budget: null,
          forecast: null,
          round: 0,
        },
        outlets: {},
        benchmarks: {},
        milestones: {},
        // raws
        raw: {},
        wdRaw: {},
      };

      // console.log("calc", performance.now());
      // console.log("filterData", filterData);
      // console.log("report_id", report_id);
      // console.log("rawData", rawData.actions);
      // console.log("dependants", dependants);

      // format
      if (filterData["milestone"] === "monthly") {
        data.id = MZ.DateIds.Ym;
        data.format = "Y-m";
      } else if (filterData["milestone"] === "weekly") {
        data.id = MZ.DateIds.YW;
        data.format = "Y-W";
      } else {
        data.id = MZ.DateIds.Ymd;
        data.format = "Y-m-d";
      }

      // report
      if (report_id) {
        let report = dependants["reports"].find(
          (c) => c["report_id"] === report_id
        );
        data.report.id = report["report_id"];
        data.report.title = report["title"];
        data.report.keys = KPIs[report["report_id"]].keys;
        data.report.cKeys = KPIs[report["report_id"]].cKeys;
        data.report.actual = KPIs[report["report_id"]].actual;
        data.report.budget = KPIs[report["report_id"]].budget;
        data.report.forecast = KPIs[report["report_id"]].forecast;
        data.report.round = KPIs[report["report_id"]].round;
        data.report.multiply = KPIs[report["report_id"]].multiply;
      }
      // currency
      if (filterData["currency"]) {
        let currency = dependants["currencies"].find(
          (c) => c["id"] === filterData["currency"]
        );
        if (currency) {
          data.currency.name = currency["name"];
          data.currency.symbol = currency["id"].toUpperCase();
          // currency
          let t = currency["r_timestamp"].split(",");
          let r = currency["r_rate"].split(",");
          for (var i in t) {
            data.currency.rates.push({
              timestamp: new MZ.DateTime(t[i]),
              rate: parseFloat(r[i]),
            });
          }
          // sort
          data.currency.rates = MZ.Arr.sort(data.currency.rates, false, "timestamp", (v) => v.getTime());
        }
      }
      // outlets
      for (let obj of dependants["outlets"]) {
        if (
          (filterData["outlets"] && !filterData["outlets"].includes(obj["id"]))
        ) continue;
        let outlet = {
          id: obj["id"],
          name: obj["name"],
          name_alt: obj["name_alt"],
          color: MZ.Color.randomColor(obj["id"], 100, 35),
          stat_seats: parseFloat(obj["stat_seats"]),
          stat_size: parseFloat(obj["stat_size"]),
          stat_work_hours: parseFloat(obj["stat_work_hours"]),
          benchmarks: null,
        };
        // get benchmarks
        let ids = [...(obj["benchmarks"] ?? "").split(","), ...filterData[`benchmarks-${obj['id']}`] ?? []];
        let iB = {};
        let oB = {};
        for (let id of ids) {
          var bOutlet = dependants["outlets"].find((o) => o.id === id);
          if (bOutlet) {
            if (filterData[`benchmarks-${obj['id']}`] && !filterData[`benchmarks-${obj['id']}`].includes(id)) continue;
            iB[bOutlet["id"]] = {
              id: bOutlet["id"],
              name: bOutlet["name"],
              name_alt: bOutlet["name_alt"],
              stat_seats: parseFloat(bOutlet["stat_seats"]),
              stat_size: parseFloat(bOutlet["stat_size"]),
              stat_work_hours: parseFloat(bOutlet["stat_work_hours"]),
              raw: {},
            };
            continue;
          }
          bOutlet = dependants["benchmarks"].find((o) => o.id === id);
          if (bOutlet) {
            oB[bOutlet["id"]] = {
              id: bOutlet["id"],
              name: bOutlet["name"],
              name_alt: bOutlet["name_alt"],
              stat_seats: parseFloat(bOutlet["stat_seats"]),
              stat_size: parseFloat(bOutlet["stat_size"]),
              stat_work_hours: parseFloat(bOutlet["stat_work_hours"]),
              raw: {},
            };
            continue;
          }
        }
        if (filterData['outBenchs'] && !filterData['outBenchs'].includes(obj['id'])) {
          oB = {}
        }
        if (MZ.Length(iB) > 0 || MZ.Length(oB) > 0) {
          outlet.benchmarks = {
            ids: ids,
            in: iB,
            out: oB,
          };
        }
        //
        data.outlets[obj["id"]] = outlet;
        data.raw[obj["id"]] = {};
      }
      // benchmarks
      for (let obj of dependants["benchmarks"]) {
        let benchmark = {
          id: obj["id"],
          name: obj["name"],
          name_alt: obj["name_alt"],
          stat_seats: parseFloat(obj["stat_seats"]),
          stat_size: parseFloat(obj["stat_size"]),
          stat_work_hours: parseFloat(obj["stat_work_hours"]),
        };
        data.benchmarks[obj["id"]] = benchmark;
      }
      // milestones
      let startDate = new MZ.DateTime(filterData["start_date"]).setSOW(
        filterData["first_weekday"]
      );
      let endDate = new MZ.DateTime(filterData["end_date"]).setSOW(
        filterData["first_weekday"]
      );
      let comparisonDate = new MZ.DateTime(filterData["old_date"]).setSOW(
        filterData["first_weekday"]
      );
      if (filterData["extras"] && filterData["extras"].includes("compareWeekdays")) {
        comparisonDate = comparisonDate.weekFirstDay(startDate.weekday());
      }
      let days = Math.ceil(endDate.totalDays() - startDate.totalDays());
      for (let d = 0; d <= days; d++) {
        let cDate = startDate.addDays(d);
        let oDate = comparisonDate.addDays(d);
        let weekday = cDate.weekday().toString();
        let monthday = cDate.monthDay().toString();
        // key
        let key = cDate.getTime(data.id);
        // name
        if (
          (!filterData["weekdays"] ||
            filterData["weekdays"].includes(weekday)) &&
          (!filterData["monthdays"] ||
            filterData["monthdays"].includes(monthday))
        ) {
          // milestones
          if (!data.milestones[key]) {
            // milestone name
            let name = null;
            if (filterData["milestone"] === "daily")
              name = cDate.weekdayText(true);
            else if (filterData["milestone"] === "weekly")
              name = cDate.weekFirstDay().getFormated("Y-m-d");
            else name = cDate.monthText(true);

            data.milestones[key] = {
              date: cDate.getFormated(data.format),
              tKey: key,
              name: name,
              comparison: oDate,
              cKey: oDate.getTime(data.id),
              date1: (filterData["milestone"] === "monthly" ? cDate.setDay(1) : (filterData["milestone"] === "weekly" ? cDate.weekFirstDay() : cDate)),
              date2: (filterData["milestone"] === "monthly" ? cDate.setDay(cDate.monthDays()) : (filterData["milestone"] === "weekly" ? cDate.weekFirstDay().addDays(6) : cDate))
            };
          }

          // trans
          for (let oI in data.raw) {
            data.raw[oI][key] = {
              t: {},
              c: {},
              tb: {},
              cb: {},
            };
          }
        }
      }
      for (let i = 0; i < 7; i++) {
        if (
          !filterData["weekdays"] ||
          filterData["weekdays"].includes(`${i}`)
        ) {
          data.wdRaw[`${MZ.Num.loop(filterData["first_weekday"] ?? 0, i, 0, 6)}`] = {
            t: {},
            c: {},
            tb: {},
            cb: {},
            count: 0,
            order: i,
          };
        }
      }
      // trans
      for (let tran of rawData["transactions"]) {
        let date = new MZ.DateTime(tran["year"], tran["month"], tran["day"]);
        let weekday = date.weekday().toString();
        let monthday = date.monthDay().toString();
        // key
        let key = date.getTime(data.id);
        // name
        if (
          data.milestones[key] &&
          (!filterData["weekdays"] ||
            filterData["weekdays"].includes(weekday)) &&
          (!filterData["monthdays"] ||
            filterData["monthdays"].includes(monthday))
        ) {
          let oid = tran[portal === "corporate" ? "property_id" : "outlet_id"];
          if (data.outlets[oid]) {
            // trans
            data.raw[oid][key].t = rawTran(
              data.raw[oid][key].t,
              data.report.keys,
              tran, date, data.report.cKeys, data.currency.rates

            );
            // weekly
            if (data.wdRaw[weekday])
              data.wdRaw[weekday].t = rawTran(
                data.wdRaw[weekday].t,
                data.report.keys,
                tran, date, data.report.cKeys, data.currency.rates

              );
            // weekly count
            if (data.wdRaw[weekday]) data.wdRaw[weekday].count++
          }
          // bench
          for (let k in data.outlets) {
            let out = data.outlets[k];
            if (out.benchmarks && out.benchmarks.in[oid]) {
              data.raw[k][key].tb[oid] = rawTran(
                data.raw[k][key].tb[oid],
                data.report.keys,
                tran, date, data.report.cKeys, data.currency.rates

              );
              // weekly
              if (data.wdRaw[weekday])
                data.wdRaw[weekday].tb[oid] = rawTran(
                  data.wdRaw[weekday].tb[oid],
                  data.report.keys,
                  tran, date, data.report.cKeys, data.currency.rates

                );
            }
          }
        }
      }
      // comp
      for (let tran of rawData["comparison-transactions"]) {
        let date = new MZ.DateTime(tran["year"], tran["month"], tran["day"]);
        let weekday = date.weekday().toString();
        let monthday = date.monthDay().toString();
        // key
        let key = date.getTime(data.id);
        //
        let mi = Object.values(data.milestones).findIndex(
          (o) => o.cKey === key
        );
        if (mi > -1) key = Object.keys(data.milestones)[mi];
        // name
        if (
          mi > -1 &&
          (!filterData["weekdays"] ||
            filterData["weekdays"].includes(weekday)) &&
          (!filterData["monthdays"] ||
            filterData["monthdays"].includes(monthday))
        ) {
          let oid = tran[portal === "corporate" ? "property_id" : "outlet_id"];
          if (data.outlets[oid]) {
            // trans
            data.raw[oid][key].c = rawTran(
              data.raw[oid][key].c,
              data.report.keys,
              tran, date, data.report.cKeys, data.currency.rates
            );
            // weekly
            if (data.wdRaw[weekday])
              data.wdRaw[weekday].c = rawTran(
                data.wdRaw[weekday].c,
                data.report.keys,
                tran, date, data.report.cKeys, data.currency.rates
              );
          }
          // branch
          for (let k in data.outlets) {
            let out = data.outlets[k];
            if (out.benchmarks && out.benchmarks.in[oid]) {
              data.raw[k][key].cb[oid] = rawTran(
                data.raw[k][key].cb[oid],
                data.report.keys,
                tran, date, data.report.cKeys, data.currency.rates
              );
              // weekly
              if (data.wdRaw[weekday])
                data.wdRaw[weekday].cb[oid] = rawTran(
                  data.wdRaw[weekday].cb[oid],
                  data.report.keys,
                  tran, date, data.report.cKeys, data.currency.rates
                );
            }
          }
        }
      }
      // benchmark
      for (let tran of rawData["benchmarks"]) {
        let date = new MZ.DateTime(tran["year"], tran["month"], tran["day"]);
        let weekday = date.weekday().toString();
        let monthday = date.monthDay().toString();
        // key
        let key = date.getTime(data.id);
        // name
        if (
          data.milestones[key] &&
          (!filterData["weekdays"] ||
            filterData["weekdays"].includes(weekday)) &&
          (!filterData["monthdays"] ||
            filterData["monthdays"].includes(monthday))
        ) {
          let oid = tran[portal === "corporate" ? "property_id" : "outlet_id"];
          // branch
          for (let k in data.outlets) {
            let out = data.outlets[k];
            if (out.benchmarks && out.benchmarks.out[oid]) {
              data.raw[k][key].tb[oid] = rawTran(
                data.raw[k][key].tb[oid],
                data.report.keys,
                tran, date, data.report.cKeys, data.currency.rates
              );
              // weekly
              if (data.wdRaw[weekday])
                data.wdRaw[weekday].tb[oid] = rawTran(
                  data.wdRaw[weekday].tb[oid],
                  data.report.keys,
                  tran, date, data.report.cKeys, data.currency.rates
                );
            }
          }
        }
      }
      // comp benchmark
      for (let tran of rawData["comparison-benchmarks"]) {
        let date = new MZ.DateTime(tran["year"], tran["month"], tran["day"]);
        let weekday = date.weekday().toString();
        let monthday = date.monthDay().toString();
        // key
        let key = date.getTime(data.id);
        // name
        let mi = Object.values(data.milestones).findIndex(
          (o) => o.cKey === key
        );
        if (mi > -1) key = Object.keys(data.milestones)[mi];
        // name
        if (
          mi > -1 &&
          (!filterData["weekdays"] ||
            filterData["weekdays"].includes(weekday)) &&
          (!filterData["monthdays"] ||
            filterData["monthdays"].includes(monthday))
        ) {
          let oid = tran[portal === "corporate" ? "property_id" : "outlet_id"];
          // branch
          for (let k in data.outlets) {
            let out = data.outlets[k];
            if (out.benchmarks && out.benchmarks.out[oid]) {
              data.raw[k][key].cb[oid] = rawTran(
                data.raw[k][key].cb[oid],
                data.report.keys,
                tran, date, data.report.cKeys, data.currency.rates
              );
              // weekly
              if (data.wdRaw[weekday])
                data.wdRaw[weekday].cb[oid] = rawTran(
                  data.wdRaw[weekday].cb[oid],
                  data.report.keys,
                  tran, date, data.report.cKeys, data.currency.rates
                );
            }
          }
        }
      }
      // process
      let pData = processRaw(
        data.raw,
        data.wdRaw,
        data.id,
        data.report,
        data.currency,
        data.milestones,
        data.outlets,
        filterData,
        rawData['actions'],
      );

      // console.log(
      //   "size",
      //   MZ.Round(new TextEncoder().encode(JSON.stringify(data)).length / 1024, 2),
      //   MZ.Round(new TextEncoder().encode(JSON.stringify(pData)).length / 1024, 2),
      //   filterData
      // );

      return MZ.Success(
        null,
        pData
      );
    } catch (err) {
      console.log(err);
      return MZ.Error(err);
    }

    function rawTran(obj, keys, tran, date, cKeys, rates) {
      if (!obj) obj = {};
      for (let k of keys) {
        if (!obj[k]) obj[k] = 0;
        let v = parseFloat(tran[k] ?? 0);
        if (v !== 0 && cKeys && cKeys.includes(k)) v = changeToCurr(rates, date, v);
        obj[k] += v;
      }
      return obj;

      function changeToCurr(rates, date, value) {
        if (!rates) return value;
        let v = value;
        for (let i in rates) {
          let r = rates[i];
          if (date.compare(r.timestamp) >= 0) {
            v = value * r.rate;
          }
        }
        return v;
      }
    }

    function processRaw(
      raw,
      wdRaw,
      format,
      report,
      currency,
      milestones,
      outlets,
      filters,
      actions
    ) {
      let obj = {
        milestones: milestones,
        actions: actions,
        areasOfConcern: 0,
        outlets: outlets,
        report: report,
        round: report.round,
        count: Object.keys(milestones).length,
        overall: null,
        overallGraph: null,
        overallOutlets: null,
        details: null,
        detailsTotal: null,
        detailsWdAvg: null,
        detailsWdAvgTotal: null,
        detailsWdTtl: null,
        wdAvg: false,
        wdTtl: false,
      };
      // totals
      for (let o in raw) {
        for (let t in raw[o]) {
          // overall
          obj.overall = overallCalc(obj.overall, raw[o][t], report, currency);
          // overall Graph
          obj.overallGraph = overallGraphCalc(
            obj.overall,
            raw[o][t],
            obj.milestones[t],
            report,
            currency
          );
          // overall Outlets
          obj.overallOutlets = overallOutletsCalc(
            obj.overallOutlets,
            raw[o][t],
            o,
            report,
            currency
          );
          // details
          obj.details = detailsCalc(
            obj.details,
            raw[o][t],
            o,
            obj.milestones[t],
            Object.keys(outlets).length,
            report,
            currency, actions, format
          );
          obj.detailsTotal = detailsTotalCalc(
            obj.detailsTotal,
            raw[o][t],
            o,
            Object.keys(outlets).length,
            report,
            currency
          );
        }
      }
      // totals
      for (let w in wdRaw) {
        if (filterData["extras"] && filters["extras"].includes("avgWeekdays"))
          obj.detailsWdAvg = detailsWdAvgCalc(
            obj.detailsWdAvg,
            wdRaw[w],
            w,
            Object.keys(outlets).length,
            report,
            currency
          );

        if (filterData["extras"] && filters["extras"].includes("totalWeekdays"))
          obj.detailsWdTtl = detailsWdTtlCalc(
            obj.detailsWdTtl,
            wdRaw[w],
            w,
            Object.keys(outlets).length,
            report,
            currency
          );
      }

      // weekdayavg total
      obj.detailsWdAvgTotal = detailsWdAvgTtlCalc(
        obj.detailsWdAvg,
        Object.keys(outlets).length,
        report,
        currency
      );

      // weekdays
      if (obj.count > 1) {
        if (obj.detailsWdAvg) obj.wdAvg = Object.keys(obj.detailsWdAvg).length > 0;
        if (obj.detailsWdTtl) obj.wdTtl = Object.keys(obj.detailsWdTtl).length > 0;
      }

      // areas of concern
      let negPerc = filterData['concern'] ?? 0;
      for (let o in obj.overallOutlets) {
        let out = obj.overallOutlets[o];
        let pac = -out.actual * (negPerc / 100);
        let c = 0;
        if (out.cVariance < pac) c++;
        if (out.bVariance < pac) c++;
        if (out.fVariance < pac) c++;
        obj.overallOutlets[o].areasOfConcern = c;
        if (c > 0) obj.areasOfConcern++;
      }

      return obj;

      function overallCalc(obj, raw, report, currency) {
        if (!obj)
          obj = {
            raw: {
              t: {},
              c: {},
            },
            actual: 0,
            cActual: 0,
            cVariance: 0,
            bActual: 0,
            bVariance: 0,
            fActual: 0,
            fVariance: 0,
          };

        // raw
        if (raw) {
          for (let k in raw.t) {
            if (!obj.raw.t[k]) obj.raw.t[k] = 0;
            obj.raw.t[k] += raw.t[k];
          }
          for (let k in raw.c) {
            if (!obj.raw.c[k]) obj.raw.c[k] = 0;
            obj.raw.c[k] += raw.c[k];
          }
        }
        // process
        obj.actual = MZ.Round(report.actual(obj.raw.t), report.round);
        obj.cActual = MZ.Round(report.actual(obj.raw.c), report.round);
        obj.cVariance = MZ.Round(obj.actual - obj.cActual, report.round);
        obj.bActual = MZ.Round(report.budget(obj.raw.t), report.round);
        obj.bVariance = MZ.Round(obj.actual - obj.bActual, report.round);
        obj.fActual = MZ.Round(report.forecast(obj.raw.t), report.round);
        obj.fVariance = MZ.Round(obj.actual - obj.fActual, report.round);

        return obj;
      }
      function overallGraphCalc(obj, raw, ms, report, currency) {
        if (!obj) obj = {};
        // milestone
        if (!obj[ms.tKey]) {
          obj[ms.tKey] = {
            raw: {
              t: {},
              c: {},
            },
            actual: 0,
            cActual: 0,
            bActual: 0,
            fActual: 0,
          };
        }
        // raw
        if (raw) {
          if (!obj[ms.tKey].raw) obj[ms.tKey].raw = {};
          for (let k in raw.t) {
            if (!obj[ms.tKey].raw.t[k]) obj[ms.tKey].raw.t[k] = 0;
            obj[ms.tKey].raw.t[k] += raw.t[k];
          }
          for (let k in raw.c) {
            if (!obj[ms.tKey].raw.c[k]) obj[ms.tKey].raw.c[k] = 0;
            obj[ms.tKey].raw.c[k] += raw.c[k];
          }
        }
        // process
        obj[ms.tKey].actual = MZ.Round(report.actual(obj[ms.tKey].raw.t), report.round);
        obj[ms.tKey].cActual = MZ.Round(report.actual(obj[ms.tKey].raw.c), report.round);
        obj[ms.tKey].bActual = MZ.Round(report.budget(obj[ms.tKey].raw.t), report.round);
        obj[ms.tKey].fActual = MZ.Round(report.forecast(obj[ms.tKey].raw.t), report.round);

        return obj;
      }
      function overallOutletsCalc(obj, raw, oid, report, currency) {
        if (!obj) obj = {};
        // milestone
        if (!obj[oid]) {
          obj[oid] = {
            raw: {
              t: {},
              c: {},
            },
            areasOfConcern: 0,
            actual: 0,
            cActual: 0,
            cVariance: 0,
            bActual: 0,
            bVariance: 0,
            fActual: 0,
            fVariance: 0,
          };
        }
        // raw
        if (raw) {
          if (!obj[oid].raw) obj[oid].raw = {};
          for (let k in raw.t) {
            if (!obj[oid].raw.t[k]) obj[oid].raw.t[k] = 0;
            obj[oid].raw.t[k] += raw.t[k];
          }
          for (let k in raw.c) {
            if (!obj[oid].raw.c[k]) obj[oid].raw.c[k] = 0;
            obj[oid].raw.c[k] += raw.c[k];
          }
        }
        // process
        obj[oid].actual = MZ.Round(report.actual(obj[oid].raw.t), report.round);
        obj[oid].cActual = MZ.Round(report.actual(obj[oid].raw.c), report.round);
        obj[oid].cVariance = MZ.Round(obj[oid].actual - obj[oid].cActual, report.round);
        obj[oid].bActual = MZ.Round(report.budget(obj[oid].raw.t), report.round);
        obj[oid].bVariance = MZ.Round(obj[oid].actual - obj[oid].bActual, report.round);
        obj[oid].fActual = MZ.Round(report.forecast(obj[oid].raw.t), report.round);
        obj[oid].fVariance = MZ.Round(obj[oid].actual - obj[oid].fActual, report.round);
        return obj;
      }

      function detailsCalc(obj, raw, oid, ms, outletsCount, report, currency, actions, format) {
        if (!obj) obj = {};
        // milestone
        if (!obj[ms.tKey]) {
          obj[ms.tKey] = {
            raw: {
              t: {},
              c: {},
              tb: {},
              cb: {},
              tbt: {},
              cbt: {},
            },
            outlets: {},
            actual: 0,
            bActual: 0,
            cActual: 0,
            cbActual: 0,
            tChg: 0,
            bChg: 0,
            index: 0,
            chg: 0,
            rank: 0,
            rankCount: 0,
            actions: [],
            actionCount: 0,
          };
          for (let action of actions) {
            if (MZ.DateTime.periodsIntersect(ms.date1, ms.date2, action['startstamp'], action['endstamp'], format)) {
              obj[ms.tKey].actions.push(action['id'])
            }
          }
          obj[ms.tKey].actionCount = obj[ms.tKey].actions.length;
        }
        if (!obj[ms.tKey].outlets[oid]) {
          obj[ms.tKey].outlets[oid] = { raw: { t: {} }, actual: 0 };
        }
        // raw
        if (raw) {
          for (let k in raw.t) {
            if (!obj[ms.tKey].raw.t[k]) obj[ms.tKey].raw.t[k] = 0;
            obj[ms.tKey].raw.t[k] += raw.t[k];

            // outlet
            if (!obj[ms.tKey].outlets[oid].raw.t[k])
              obj[ms.tKey].outlets[oid].raw.t[k] = 0;
            obj[ms.tKey].outlets[oid].raw.t[k] += raw.t[k];
          }
          for (let k in raw.c) {
            if (!obj[ms.tKey].raw.c[k]) obj[ms.tKey].raw.c[k] = 0;
            obj[ms.tKey].raw.c[k] += raw.c[k];
          }
          for (let b in raw.tb) {
            if (!obj[ms.tKey].raw.tb[b]) obj[ms.tKey].raw.tb[b] = {};
            for (let k in raw.tb[b]) {
              if (!obj[ms.tKey].raw.tb[b][k]) obj[ms.tKey].raw.tb[b][k] = 0;
              obj[ms.tKey].raw.tb[b][k] += raw.tb[b][k];
            }
          }
          for (let b in raw.cb) {
            if (!obj[ms.tKey].raw.cb[b]) obj[ms.tKey].raw.cb[b] = {};
            for (let k in raw.cb[b]) {
              if (!obj[ms.tKey].raw.cb[b][k]) obj[ms.tKey].raw.cb[b][k] = 0;
              obj[ms.tKey].raw.cb[b][k] += raw.cb[b][k];
            }
          }
        }
        obj[ms.tKey].raw.tbt = sumB(obj[ms.tKey].raw.tb);
        obj[ms.tKey].raw.cbt = sumB(obj[ms.tKey].raw.cb);
        obj[ms.tKey].outlets[oid].actual = MZ.Round(
          report.actual(obj[ms.tKey].outlets[oid].raw.t),
          2
        );
        // process
        obj[ms.tKey].actual = MZ.Round(report.actual(obj[ms.tKey].raw.t), report.round);
        obj[ms.tKey].bActual = MZ.Round(
          report.actual(obj[ms.tKey].raw.tbt)
          * (report.multiply ? outletsCount : 1),
          report.round);
        obj[ms.tKey].cActual = MZ.Round(report.actual(obj[ms.tKey].raw.c), report.round);
        obj[ms.tKey].cbActual = MZ.Round(
          report.actual(obj[ms.tKey].raw.cbt)
          * (report.multiply ? outletsCount : 1),
          report.round
        );
        obj[ms.tKey].tChg = MZ.Round(
          MyReportsM.getChg(obj[ms.tKey].actual, obj[ms.tKey].cActual),
          2
        );
        obj[ms.tKey].bChg = MZ.Round(
          MyReportsM.getChg(obj[ms.tKey].bActual, obj[ms.tKey].cbActual),
          2
        );
        obj[ms.tKey].index = MZ.Round(
          MyReportsM.getIndex(obj[ms.tKey].actual, obj[ms.tKey].bActual),
          2
        );
        obj[ms.tKey].chg = MZ.Round(
          MyReportsM.getMainChg(
            obj[ms.tKey].index,
            obj[ms.tKey].cActual,
            obj[ms.tKey].cbActual
          ),
          2
        );
        obj[ms.tKey].rank = rank(
          obj[ms.tKey].actual,
          obj[ms.tKey].raw.tb,
          report.actual
        );
        obj[ms.tKey].rankCount = Object.keys(obj[ms.tKey].raw.tb).length + 1;

        return obj;
      }
      function detailsTotalCalc(obj, raw, oid, outletsCount, report, currency) {
        if (!obj)
          obj = {
            raw: {
              t: {},
              c: {},
              tb: {},
              cb: {},
              tbt: {},
              cbt: {},
            },
            outlets: {},
            actual: 0,
            bActual: 0,
            cActual: 0,
            cbActual: 0,
            tChg: 0,
            bChg: 0,
            index: 0,
            chg: 0,
            rank: 0,
            rankCount: 0,
          };
        if (!obj.outlets[oid]) {
          obj.outlets[oid] = { raw: { t: {} }, actual: 0 };
        }
        // raw
        if (raw) {
          for (let k in raw.t) {
            if (!obj.raw.t[k]) obj.raw.t[k] = 0;
            obj.raw.t[k] += raw.t[k];
            // outlet
            if (!obj.outlets[oid].raw.t[k]) obj.outlets[oid].raw.t[k] = 0;
            obj.outlets[oid].raw.t[k] += raw.t[k];
          }
          for (let k in raw.c) {
            if (!obj.raw.c[k]) obj.raw.c[k] = 0;
            obj.raw.c[k] += raw.c[k];
          }
          for (let b in raw.tb) {
            if (!obj.raw.tb[b]) obj.raw.tb[b] = {};
            for (let k in raw.tb[b]) {
              if (!obj.raw.tb[b][k]) obj.raw.tb[b][k] = 0;
              obj.raw.tb[b][k] += raw.tb[b][k];
            }
          }
          for (let b in raw.cb) {
            if (!obj.raw.cb[b]) obj.raw.cb[b] = {};
            for (let k in raw.cb[b]) {
              if (!obj.raw.cb[b][k]) obj.raw.cb[b][k] = 0;
              obj.raw.cb[b][k] += raw.cb[b][k];
            }
          }
        }
        obj.raw.tbt = sumB(obj.raw.tb);
        obj.raw.cbt = sumB(obj.raw.cb);

        obj.outlets[oid].actual = MZ.Round(
          report.actual(obj.outlets[oid].raw.t),
          2
        );
        // process
        obj.actual = MZ.Round(report.actual(obj.raw.t), 2);
        obj.bActual = MZ.Round(report.actual(obj.raw.tbt)
          * (report.multiply ? outletsCount : 1), 2);
        obj.cActual = MZ.Round(report.actual(obj.raw.c), 2);
        obj.cbActual = MZ.Round(report.actual(obj.raw.cbt)
          * (report.multiply ? outletsCount : 1), 2);
        obj.tChg = MZ.Round(MyReportsM.getChg(obj.actual, obj.cActual), 2);
        obj.bChg = MZ.Round(MyReportsM.getChg(obj.bActual, obj.cbActual), 2);
        obj.index = MZ.Round(MyReportsM.getIndex(obj.actual, obj.bActual), 2);
        obj.chg = MZ.Round(
          MyReportsM.getMainChg(obj.index, obj.cActual, obj.cbActual),
          2
        );
        obj.rank = rank(obj.actual, obj.raw.tb, report.actual);
        obj.rankCount = Object.keys(obj.raw.tb).length + 1;

        return obj;
      }

      function detailsWdAvgCalc(obj, raw, wid, outletsCount, report, currency) {
        if (!obj) obj = {};
        // milestone
        if (!obj[wid]) {
          obj[wid] = {
            raw: {
              t: {},
              c: {},
              tb: {},
              cb: {},
              tbt: {},
              cbt: {},
            },
            div: {
              t: {},
              c: {},
              tb: {},
              cb: {},
              tbt: {},
              cbt: {},
            },
            num: wid,
            actual: 0,
            bActual: 0,
            cActual: 0,
            cbActual: 0,
            tChg: 0,
            bChg: 0,
            index: 0,
            chg: 0,
            rank: 0,
            rankCount: 0,
            order: raw.order,
          };
        }
        // raw
        if (raw) {
          for (let k in raw.t) {
            if (!obj[wid].raw.t[k]) obj[wid].raw.t[k] = 0;
            obj[wid].raw.t[k] += raw.t[k];
          }
          for (let k in raw.c) {
            if (!obj[wid].raw.c[k]) obj[wid].raw.c[k] = 0;
            obj[wid].raw.c[k] += raw.c[k];
          }
          for (let b in raw.tb) {
            if (!obj[wid].raw.tb[b]) obj[wid].raw.tb[b] = {};
            for (let k in raw.tb[b]) {
              if (!obj[wid].raw.tb[b][k]) obj[wid].raw.tb[b][k] = 0;
              obj[wid].raw.tb[b][k] += raw.tb[b][k];
            }
          }
          for (let b in raw.cb) {
            if (!obj[wid].raw.cb[b]) obj[wid].raw.cb[b] = {};
            for (let k in raw.cb[b]) {
              if (!obj[wid].raw.cb[b][k]) obj[wid].raw.cb[b][k] = 0;
              obj[wid].raw.cb[b][k] += raw.cb[b][k];
            }
          }
        }
        obj[wid].div.tbt = div(sumB(obj[wid].raw.tb), raw.count);
        obj[wid].div.cbt = div(sumB(obj[wid].raw.cb), raw.count);
        // div
        obj[wid].div.t = div(obj[wid].raw.t, raw.count);
        obj[wid].div.c = div(obj[wid].raw.c, raw.count);
        for (let b in obj[wid].raw.tb) {
          obj[wid].div.tb[b] = div(obj[wid].raw.tb[b], raw.count);
        }
        for (let b in obj[wid].raw.cb) {
          obj[wid].div.cb[b] = div(obj[wid].raw.cb[b], raw.count);
        }
        // process
        obj[wid].actual = MZ.Round(report.actual(obj[wid].div.t), report.round);
        obj[wid].bActual = MZ.Round(report.actual(obj[wid].div.tbt)
          * (report.multiply ? outletsCount : 1), report.round);
        obj[wid].cActual = MZ.Round(report.actual(obj[wid].div.c), report.round);
        obj[wid].cbActual = MZ.Round(report.actual(obj[wid].div.cbt)
          * (report.multiply ? outletsCount : 1), report.round);
        obj[wid].tChg = MZ.Round(
          MyReportsM.getChg(obj[wid].actual, obj[wid].cActual),
          2
        );
        obj[wid].bChg = MZ.Round(
          MyReportsM.getChg(obj[wid].bActual, obj[wid].cbActual),
          2
        );
        obj[wid].index = MZ.Round(
          MyReportsM.getIndex(obj[wid].actual, obj[wid].bActual),
          2
        );
        obj[wid].chg = MZ.Round(
          MyReportsM.getMainChg(
            obj[wid].index,
            obj[wid].cActual,
            obj[wid].cbActual
          ),
          2
        );
        obj[wid].rank = rank(obj[wid].actual, obj[wid].div.tb, report.actual);
        obj[wid].rankCount = Object.keys(obj[wid].div.tb).length + 1;

        return obj;
      }
      function detailsWdAvgTtlCalc(raws, outletsCount, report, currency) {
        let obj = {
          raw: {
            t: {},
            c: {},
            tb: {},
            cb: {},
            tbt: {},
            cbt: {},
          },
          actual: 0,
          bActual: 0,
          cActual: 0,
          cbActual: 0,
          tChg: 0,
          bChg: 0,
          index: 0,
          chg: 0,
          rank: 0,
          rankCount: 0,
        };
        // raw
        if (raws) {
          for (let i in raws) {
            let raw = raws[i].div;
            for (let k in raw.t) {
              if (!obj.raw.t[k]) obj.raw.t[k] = 0;
              obj.raw.t[k] += raw.t[k];
            }
            for (let k in raw.c) {
              if (!obj.raw.c[k]) obj.raw.c[k] = 0;
              obj.raw.c[k] += raw.c[k];
            }
            for (let b in raw.tb) {
              if (!obj.raw.tb[b]) obj.raw.tb[b] = {};
              for (let k in raw.tb[b]) {
                if (!obj.raw.tb[b][k]) obj.raw.tb[b][k] = 0;
                obj.raw.tb[b][k] += raw.tb[b][k];
              }
            }
            for (let b in raw.cb) {
              if (!obj.raw.cb[b]) obj.raw.cb[b] = {};
              for (let k in raw.cb[b]) {
                if (!obj.raw.cb[b][k]) obj.raw.cb[b][k] = 0;
                obj.raw.cb[b][k] += raw.cb[b][k];
              }
            }
          }
        }
        obj.raw.tbt = sumB(obj.raw.tb);
        obj.raw.cbt = sumB(obj.raw.cb);
        // process
        obj.actual = MZ.Round(report.actual(obj.raw.t), 2);
        obj.bActual = MZ.Round(report.actual(obj.raw.tbt)
          * (report.multiply ? outletsCount : 1), 2);
        obj.cActual = MZ.Round(report.actual(obj.raw.c), 2);
        obj.cbActual = MZ.Round(report.actual(obj.raw.cbt)
          * (report.multiply ? outletsCount : 1), 2);
        obj.tChg = MZ.Round(MyReportsM.getChg(obj.actual, obj.cActual), 2);
        obj.bChg = MZ.Round(MyReportsM.getChg(obj.bActual, obj.cbActual), 2);
        obj.index = MZ.Round(MyReportsM.getIndex(obj.actual, obj.bActual), 2);
        obj.chg = MZ.Round(
          MyReportsM.getMainChg(obj.index, obj.cActual, obj.cbActual),
          2
        );
        obj.rank = rank(obj.actual, obj.raw.tb, report.actual);
        obj.rankCount = Object.keys(obj.raw.tb).length + 1;

        return obj;

      }
      function detailsWdTtlCalc(obj, raw, wid, outletsCount, report, currency) {
        if (!obj) obj = {};
        // milestone
        if (!obj[wid]) {
          obj[wid] = {
            raw: {
              t: {},
              c: {},
              tb: {},
              cb: {},
              tbt: {},
              cbt: {},
            },
            num: wid,
            actual: 0,
            bActual: 0,
            cActual: 0,
            cbActual: 0,
            tChg: 0,
            bChg: 0,
            index: 0,
            chg: 0,
            rank: 0,
            rankCount: 0,
            order: raw.order,
          };
        }
        // raw
        if (raw) {
          for (let k in raw.t) {
            if (!obj[wid].raw.t[k]) obj[wid].raw.t[k] = 0;
            obj[wid].raw.t[k] += raw.t[k];
          }
          for (let k in raw.c) {
            if (!obj[wid].raw.c[k]) obj[wid].raw.c[k] = 0;
            obj[wid].raw.c[k] += raw.c[k];
          }
          for (let b in raw.tb) {
            if (!obj[wid].raw.tb[b]) obj[wid].raw.tb[b] = {};
            for (let k in raw.tb[b]) {
              if (!obj[wid].raw.tb[b][k]) obj[wid].raw.tb[b][k] = 0;
              obj[wid].raw.tb[b][k] += raw.tb[b][k];
            }
          }
          for (let b in raw.cb) {
            if (!obj[wid].raw.cb[b]) obj[wid].raw.cb[b] = {};
            for (let k in raw.cb[b]) {
              if (!obj[wid].raw.cb[b][k]) obj[wid].raw.cb[b][k] = 0;
              obj[wid].raw.cb[b][k] += raw.cb[b][k];
            }
          }
        }
        obj[wid].raw.tbt = sumB(obj[wid].raw.tb);
        obj[wid].raw.cbt = sumB(obj[wid].raw.cb);
        // process
        obj[wid].actual = MZ.Round(report.actual(obj[wid].raw.t), report.round);
        obj[wid].bActual = MZ.Round(report.actual(obj[wid].raw.tbt)
          * (report.multiply ? outletsCount : 1), report.round);
        obj[wid].cActual = MZ.Round(report.actual(obj[wid].raw.c), report.round);
        obj[wid].cbActual = MZ.Round(report.actual(obj[wid].raw.cbt)
          * (report.multiply ? outletsCount : 1), report.round);
        obj[wid].tChg = MZ.Round(
          MyReportsM.getChg(obj[wid].actual, obj[wid].cActual),
          2
        );
        obj[wid].bChg = MZ.Round(
          MyReportsM.getChg(obj[wid].bActual, obj[wid].cbActual),
          2
        );
        obj[wid].index = MZ.Round(
          MyReportsM.getIndex(obj[wid].actual, obj[wid].bActual),
          2
        );
        obj[wid].chg = MZ.Round(
          MyReportsM.getMainChg(
            obj[wid].index,
            obj[wid].cActual,
            obj[wid].cbActual
          ),
          2
        );
        obj[wid].rank = rank(obj[wid].actual, obj[wid].raw.tb, report.actual);
        obj[wid].rankCount = Object.keys(obj[wid].raw.tb).length + 1;

        return obj;
      }

      function div(obj, div = 1) {
        let nObj = {};
        for (let k in obj) {
          nObj[k] = obj[k] / div;
        }
        return nObj;
      }

      function sumB(arr) {
        let obj = {};
        for (let b in arr) {
          for (let k in arr[b]) {
            if (!obj[k]) obj[k] = NaN;
            if (!obj[k] && arr[b][k]) obj[k] = 0;
            obj[k] += arr[b][k];
          }
        }
        let len = Object.keys(arr).length;
        for (let k in obj) {
          obj[k] = obj[k] / len;
        }
        return obj;
      }
      function rank(actual, benchs, equation) {
        let rank = 1;
        for (let b in benchs) {
          if (equation(benchs[b]) > actual) rank++;
        }
        return rank;
      }
    }
  }

  // calc
  static getCurrency(v1, v2) {
    if (!v1 || !v2) return NaN;
    return ((v1 - v2) / v2) * 100;
  }
  static getChg(v1, v2) {
    if (!v1 || !v2) return NaN;
    return ((v1 - v2) / v2) * 100;
  }
  static getIndex(v1, v2) {
    if (!v1 || !v2) return NaN;
    return (v1 / v2) * 100;
  }
  static getMainChg(index, v1, v2) {
    if (!index || !v1 || !v2) return NaN;
    return (index / ((v1 / v2) * 100) - 1) * 100;
  }
}
