// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
import * as MIcons from "@mui/icons-material";
//==============================
const MyZoomDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <MUI.Button
        sx={{ width: "100%", height: "100%" }}
        onClick={() => setOpen(true)}
      >
        {props.children}
      </MUI.Button>
      <MUI.Dialog
        open={open}
        TransitionComponent={props.TransitionComponent}
        keepMounted={!!props.TransitionComponent}
        fullScreen={true}
        sx={{
          ".MuiDialogTitle-root+.MuiDialogContent-root": {
            py: 2,
            px: 2,
          },
          ".MuiDialog-paper": {
            borderRadius: 2,
          },
          margin: 2.5,
        }}
      >
        <MUI.DialogContent sx={{ height: "100%" }}>
          {props.children}
        </MUI.DialogContent>

        <MUI.IconButton
          sx={{ position: "absolute", right: 0 }}
          color="primary"
          onClick={() => setOpen(false)}
        >
          <MIcons.Close />
        </MUI.IconButton>
      </MUI.Dialog>
    </>
  );
};
MyZoomDialog.propTypes = {
  children: PropTypes.node.isRequired,
  TransitionComponent: PropTypes.object,
};
export default MyZoomDialog;
