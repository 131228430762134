// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
// components
import * as MZ from "../models/MZ";
//==============================
const MyTable = (props) => {
  const _head = (head) => {
    return MZ.If(
      head,
      <MUI.TableHead key={MZ.GetKey()} sx={{ ...head.sx }}>
        {MZ.ForEach(head.children, (k, row) => _row(row, true))}
      </MUI.TableHead>
    );
  };
  const _body = (body) => {
    return MZ.If(
      body,
      <MUI.TableBody key={MZ.GetKey()} sx={{ ...body.sx }}>
        {MZ.ForEach(body.children, (k, row) => _row(row, false))}
      </MUI.TableBody>
    );
  };
  const _row = (row, head = false) => {
    return MZ.If(
      row,
      <MUI.TableRow key={MZ.GetKey()}>
        {MZ.ForEach(row, (k, cell) => _cell(cell, head))}
      </MUI.TableRow>
    );
  };
  const _cell = (cell, head = false) => {
    return (
      <MUI.TableCell
        key={MZ.GetKey()}
        sx={{
          p: props.small ? 1 : 2,
          borderWidth: 0.25,
          backgroundColor: head ? "primary.main" : "light.main",
          borderColor: head ? "light.light" : "primary.light",
          color: head ? "light.main" : "primary.main",
          "&:nth-of-type(even)": {
            backgroundColor: head ? "primary.lighter" : "white.main",
          },
          textAlign: cell.center ?? true ? "center" : "start",
          ...cell.sx,
        }}
        colSpan={cell.colSpan ?? 1}
        rowSpan={cell.rowSpan ?? 1}
      >
        {cell.child ?? null}
      </MUI.TableCell>
    );
  };
  return (
    <MUI.TableContainer sx={props.sx}>
      <MUI.Table sx={{ minWidth: props.minWidth, borderColor: "primary.main" }}>
        {MZ.ForEach(props.heads, (k, head) => _head(head))}
        {props.bodyLoading
          ? _body({
              children: [
                [
                  {
                    child: props.bodyLoadingChild ?? (
                      <MUI.Skeleton height={100} variant="rectangular" />
                    ),
                    colSpan: props.bodyLoadingColSpan ?? 1,
                    rowSpan: props.bodyLoadingRowSpan ?? 1,
                    sx: { p: 0 },
                  },
                ],
              ],
            })
          : MZ.ForEach(props.bodies, (k, body) => _body(body))}
      </MUI.Table>
    </MUI.TableContainer>
  );
};
MyTable.propTypes = {
  minWidth: PropTypes.number,
  heads: PropTypes.array.isRequired,
  bodies: PropTypes.array.isRequired,
  sx: PropTypes.object,
  bodyLoading: PropTypes.bool,
  bodyLoadingChild: PropTypes.node,
  bodyLoadingColSpan: PropTypes.number,
  bodyLoadingRowSpan: PropTypes.number,
};
export default MyTable;
