// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MIcons from "@mui/icons-material";
// models
import * as MZ from "../../models/MZ";
// layouts
// components
import MyTable from "../../components/MyTable";
import MyTable2 from "../../components/MyTable2";
import MyChart from "../../components/MyChart";
import MyZoomDialog from "../../components/MyZoomDialog";
import MyModal from "../../components/MyModal";

//============< Details
const MyDashboardDetailsTab = (props) => {
  // const
  //
  const [actionDialog, setActionDialog] = React.useState(null);

  let graphMinHeight = 250;
  return (
    <MUI.Box>
      <MUI.Grid
        container
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ mb: 3 }}
      >
        <MUI.Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={props.portal === "outlet" ? 4 : 3}
        >
          <MUI.Paper
            className="fill"
            elevation={5}
            sx={{ minHeight: graphMinHeight }}
            square
          >
            {MZ.If(
              props.loading,
              <MUI.Skeleton height="100%" width="100%" variant="rectangular" />,
              <MyZoomDialog>
                <MyChart
                  title={MZ.Crawl(props.pData, "report/title")}
                  type={MZ.Crawl(props.pData, "count", 0) > 1 ? "line" : "bar"}
                  labels={MZ.ForEach(
                    MZ.Crawl(props.pData, "milestones"),
                    (k, v) => {
                      return v.date;
                    }
                  )}
                  dataSets={[
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(props.pData, `details/${k}/actual`, NaN);
                    }),
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(props.pData, `details/${k}/cActual`, NaN);
                    }),
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(props.pData, `details/${k}/bActual`, NaN);
                    }),
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(
                        props.pData,
                        `details/${k}/cbActual`,
                        NaN
                      );
                    }),
                  ]}
                  dataLabels={[
                    MZ.T("current"),
                    MZ.T("comparison"),
                    MZ.T("benchmark"),
                    MZ.T("benchmark-comparison"),
                  ]}
                  dataColors={[
                    new MZ.Color(10, 7, 217),
                    new MZ.Color(6, 214, 27),
                    new MZ.Color(224, 9, 9),
                    new MZ.Color(9, 217, 206),
                  ]}
                  maintainAspectRatio={false}
                />
              </MyZoomDialog>
            )}
          </MUI.Paper>
        </MUI.Grid>
        <MUI.Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={props.portal === "outlet" ? 4 : 3}
        >
          <MUI.Paper
            className="fill"
            elevation={5}
            sx={{ minHeight: graphMinHeight }}
            square
          >
            {MZ.If(
              props.loading,
              <MUI.Skeleton height="100%" width="100%" variant="rectangular" />,
              <MyZoomDialog>
                <MyChart
                  title={`${MZ.Crawl(props.pData, "report/title")}: ${MZ.T(
                    "chg-vs-comparison"
                  )}`}
                  type={MZ.Crawl(props.pData, "count", 0) > 1 ? "line" : "bar"}
                  labels={MZ.ForEach(
                    MZ.Crawl(props.pData, "milestones"),
                    (k, v) => {
                      return v.date;
                    }
                  )}
                  dataSets={[
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(props.pData, `details/${k}/tChg`, NaN);
                    }),
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(props.pData, `details/${k}/bChg`, NaN);
                    }),
                  ]}
                  dataLabels={[MZ.T("current"), MZ.T("benchmark")]}
                  dataColors={[
                    new MZ.Color(10, 7, 217),
                    new MZ.Color(224, 9, 9),
                  ]}
                  maintainAspectRatio={false}
                />
              </MyZoomDialog>
            )}
          </MUI.Paper>
        </MUI.Grid>
        {props.portal === "outlet" ? null : (
          <MUI.Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={props.portal === "outlet" ? 4 : 3}
          >
            <MUI.Paper
              className="fill"
              elevation={5}
              sx={{ minHeight: graphMinHeight }}
              square
            >
              {MZ.If(
                props.loading,
                <MUI.Skeleton
                  height="100%"
                  width="100%"
                  variant="rectangular"
                />,
                <MyZoomDialog>
                  <MyChart
                    title={`${MZ.Crawl(props.pData, "report/title")}: ${MZ.T(
                      props.portal === "corporate"
                        ? "properties-vs"
                        : "outlets-vs"
                    )}`}
                    type={
                      MZ.Crawl(props.pData, "count", 0) > 1 ? "line" : "bar"
                    }
                    labels={MZ.ForEach(
                      MZ.Crawl(props.pData, "milestones"),
                      (k, v) => {
                        return v.date;
                      }
                    )}
                    dataSets={MZ.ForEach(
                      MZ.Crawl(props.pData, "outlets"),
                      (o, v) => {
                        return MZ.ForEach(
                          MZ.Crawl(props.pData, "milestones"),
                          (k, v) => {
                            return MZ.Crawl(
                              props.pData,
                              `details/${k}/outlets/${o}/actual`,
                              NaN
                            );
                          }
                        );
                      }
                    )}
                    dataLabels={MZ.ForEach(
                      MZ.Crawl(props.pData, "outlets"),
                      (o, v) => {
                        return v.name;
                      }
                    )}
                    dataColors={MZ.ForEach(
                      MZ.Crawl(props.pData, "outlets"),
                      (o, v) => {
                        return v.color;
                      }
                    )}
                    maintainAspectRatio={false}
                  />
                </MyZoomDialog>
              )}
            </MUI.Paper>
          </MUI.Grid>
        )}
        <MUI.Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={props.portal === "outlet" ? 4 : 3}
        >
          <MUI.Paper
            className="fill"
            elevation={5}
            sx={{ minHeight: graphMinHeight }}
            square
          >
            {MZ.If(
              props.loading,
              <MUI.Skeleton height="100%" width="100%" variant="rectangular" />,
              <MyZoomDialog>
                <MyChart
                  title={`${MZ.Crawl(props.pData, "report/title")}: ${MZ.T(
                    "rank"
                  )}`}
                  type={MZ.Crawl(props.pData, "count", 0) > 1 ? "line" : "bar"}
                  labels={MZ.ForEach(
                    MZ.Crawl(props.pData, "milestones"),
                    (k, v) => {
                      return v.date;
                    }
                  )}
                  dataSets={[
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(props.pData, `details/${k}/rank`, NaN);
                    }),
                  ]}
                  dataLabels={[
                    MZ.T(
                      props.portal === "corporate"
                        ? "property"
                        : "outlet"
                    ),
                  ]}
                  dataColors={[new MZ.Color(10, 7, 217)]}
                  maintainAspectRatio={false}
                  min={0}
                  max={MZ.Crawl(props.pData, `detailsTotal/rankCount`, NaN)}
                  stepSize={1}
                />
              </MyZoomDialog>
            )}
          </MUI.Paper>
        </MUI.Grid>

        <MUI.Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={props.portal === "outlet" ? 4 : 3}
          sx={{
            display: MZ.If(MZ.Crawl(props.pData, "count", 0) <= 1, "none"),
          }}
        >
          <MUI.Paper
            className="fill"
            elevation={5}
            sx={{ minHeight: graphMinHeight }}
            square
          >
            {MZ.If(
              props.loading,
              <MUI.Skeleton height="100%" width="100%" variant="rectangular" />,
              <MyZoomDialog>
                <MyChart
                  title={
                    MZ.Crawl(props.pData, "report/title") + ": " + MZ.T("total")
                  }
                  type={"bar"}
                  labels={[MZ.T("total")]}
                  dataSets={[
                    [MZ.Crawl(props.pData, `detailsTotal/actual`, NaN)],
                    [MZ.Crawl(props.pData, `detailsTotal/cActual`, NaN)],
                    [MZ.Crawl(props.pData, `detailsTotal/bActual`, NaN)],
                    [MZ.Crawl(props.pData, `detailsTotal/cbActual`, NaN)],
                  ]}
                  dataLabels={[
                    MZ.T("current"),
                    MZ.T("comparison"),
                    MZ.T("benchmark"),
                    MZ.T("benchmark-comparison"),
                  ]}
                  dataColors={[
                    new MZ.Color(10, 7, 217),
                    new MZ.Color(6, 214, 27),
                    new MZ.Color(224, 9, 9),
                    new MZ.Color(9, 217, 206),
                  ]}
                  maintainAspectRatio={false}
                />
              </MyZoomDialog>
            )}
          </MUI.Paper>
        </MUI.Grid>
        <MUI.Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={props.portal === "outlet" ? 4 : 3}
          sx={{
            display: MZ.If(MZ.Crawl(props.pData, "count", 0) <= 1, "none"),
          }}
        >
          <MUI.Paper
            className="fill"
            elevation={5}
            sx={{ minHeight: graphMinHeight }}
            square
          >
            {MZ.If(
              props.loading,
              <MUI.Skeleton height="100%" width="100%" variant="rectangular" />,
              <MyZoomDialog>
                <MyChart
                  title={`${MZ.Crawl(props.pData, "report/title")}: ${MZ.T(
                    "total-chg-vs-comparison"
                  )}`}
                  type={"bar"}
                  labels={[MZ.T("total")]}
                  dataSets={[
                    [MZ.Crawl(props.pData, `detailsTotal/tChg`, NaN)],
                    [MZ.Crawl(props.pData, `detailsTotal/bChg`, NaN)],
                  ]}
                  dataLabels={[MZ.T("current"), MZ.T("benchmark")]}
                  dataColors={[
                    new MZ.Color(10, 7, 217),
                    new MZ.Color(224, 9, 9),
                  ]}
                  maintainAspectRatio={false}
                />
              </MyZoomDialog>
            )}
          </MUI.Paper>
        </MUI.Grid>
        {props.portal === "outlet" ? null : (
          <MUI.Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={props.portal === "outlet" ? 4 : 3}
            sx={{
              display: MZ.If(MZ.Crawl(props.pData, "count", 0) <= 1, "none"),
            }}
          >
            <MUI.Paper
              className="fill"
              elevation={5}
              sx={{ minHeight: graphMinHeight }}
              square
            >
              {MZ.If(
                props.loading,
                <MUI.Skeleton
                  height="100%"
                  width="100%"
                  variant="rectangular"
                />,
                <MyZoomDialog>
                  <MyChart
                    title={`${MZ.Crawl(props.pData, "report/title")}: ${MZ.T(
                      props.portal === "corporate"
                        ? "total-properties-vs"
                        : "total-outlets-vs"
                    )}`}
                    type={"bar"}
                    labels={[MZ.T("total")]}
                    dataSets={MZ.ForEach(
                      MZ.Crawl(props.pData, "outlets"),
                      (o, v) => {
                        return [
                          MZ.Crawl(
                            props.pData,
                            `detailsTotal/outlets/${o}/actual`,
                            NaN
                          ),
                        ];
                      }
                    )}
                    dataLabels={MZ.ForEach(
                      MZ.Crawl(props.pData, "outlets"),
                      (o, v) => {
                        return v.name;
                      }
                    )}
                    dataColors={MZ.ForEach(
                      MZ.Crawl(props.pData, "outlets"),
                      (o, v) => {
                        return v.color;
                      }
                    )}
                    maintainAspectRatio={false}
                  />
                </MyZoomDialog>
              )}
            </MUI.Paper>
          </MUI.Grid>
        )}
        <MUI.Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={props.portal === "outlet" ? 4 : 3}
          sx={{
            display: MZ.If(MZ.Crawl(props.pData, "count", 0) <= 1, "none"),
          }}
        >
          <MUI.Paper
            className="fill"
            elevation={5}
            sx={{ minHeight: graphMinHeight }}
            square
          >
            {MZ.If(
              props.loading,
              <MUI.Skeleton height="100%" width="100%" variant="rectangular" />,
              <MyZoomDialog>
                <MyChart
                  title={`${MZ.Crawl(props.pData, "report/title")}: ${MZ.T(
                    "total-rank"
                  )}`}
                  type={"bar"}
                  labels={[MZ.T("total")]}
                  dataSets={[[MZ.Crawl(props.pData, `detailsTotal/rank`, NaN)]]}
                  dataLabels={[
                    MZ.T(
                      props.portal === "corporate"
                        ? "properties"
                        : "outlets"
                    ),
                  ]}
                  dataColors={[new MZ.Color(10, 7, 217)]}
                  maintainAspectRatio={false}
                  min={0}
                  max={MZ.Crawl(props.pData, `detailsTotal/rankCount`, NaN)}
                  stepSize={1}
                />
              </MyZoomDialog>
            )}
          </MUI.Paper>
        </MUI.Grid>
      </MUI.Grid>
      <MUI.Divider sx={{ my: 2, backgroundColor: "primary.main" }} />

      <MyTable2
        small={true}
        loading={props.loading}
        loadingColSpan={12}
        groups={[
          // average WD
          {
            children: [
              [
                {
                  child: MZ.T("weekdays-average"),
                  rowSpan: 2,
                  colSpan: 2,
                },
                { child: MZ.T("current"), colSpan: 2 },
                { child: MZ.T("comparison"), colSpan: 2 },
                { child: MZ.T("chg"), colSpan: 2 },
                { child: MZ.T("index"), rowSpan: 2 },
                { child: MZ.T("chg"), rowSpan: 2 },
                { child: MZ.T("rank"), rowSpan: 2 },
                {
                  child: <MIcons.DeveloperBoard />,
                  rowSpan: 2,
                },
              ],
              [
                {
                  child: MZ.T(
                    props.portal === "corporate"
                      ? "properties"
                      : "outlets"
                  ),
                },
                { child: MZ.T("benchmark") },
                {
                  child: MZ.T(
                    props.portal === "corporate"
                      ? "properties"
                      : "outlets"
                  ),
                },
                { child: MZ.T("benchmark") },
                {
                  child: MZ.T(
                    props.portal === "corporate"
                      ? "properties"
                      : "outlets"
                  ),
                },
                { child: MZ.T("benchmark") },
              ],
            ],
            hidden: MZ.Crawl(props.pData, "wdAvg", false) === false,
            head: true,
          },
          {
            children: MZ.ForEach(
              MZ.Arr.sort(
                MZ.Arr.objToArr(MZ.Crawl(props.pData, "detailsWdAvg")),
                false,
                "order"
              ),
              (k, v) => {
                return [
                  {
                    child: `${MZ.T(
                      MZ.DateTime.weekdayNoText(v.num, false).toLowerCase()
                    )} ${MZ.T("average")}`,
                    colSpan: 2,
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(v, `actual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(v, `bActual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(v, `cActual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(v, `cbActual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(MZ.Crawl(v, `tChg`, "N/A"), 2),
                  },
                  {
                    child: MZ.Readable(MZ.Crawl(v, `bChg`, "N/A"), 2),
                  },
                  {
                    child: MZ.Readable(MZ.Crawl(v, `index`, "N/A"), 2),
                  },
                  {
                    child: MZ.Readable(MZ.Crawl(v, `chg`, "N/A"), 2),
                  },
                  {
                    child:
                      MZ.Crawl(v, `rank`, "N/A") +
                      " / " +
                      MZ.Crawl(v, `rankCount`, "N/A"),
                  },
                  { child: "-" },
                ];
              }
            ),
            hidden: MZ.Crawl(props.pData, "wdAvg", false) === false,
          },
          {
            children: [
              [
                {
                  child: MZ.T("total"),
                  colSpan: 2,
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsWdAvgTotal/actual`, "N/A"),

                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsWdAvgTotal/bActual`, "N/A"),

                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsWdAvgTotal/cActual`, "N/A"),

                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsWdAvgTotal/cbActual`, "N/A"),

                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsWdAvgTotal/tChg`, "N/A"),
                    2
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsWdAvgTotal/bChg`, "N/A"),
                    2
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsWdAvgTotal/index`, "N/A"),
                    2
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsWdAvgTotal/chg`, "N/A"),
                    2
                  ),
                },
                {
                  child:
                    MZ.Crawl(props.pData, `detailsWdAvgTotal/rank`, "N/A") +
                    " / " +
                    MZ.Crawl(props.pData, `detailsWdAvgTotal/rankCount`, "N/A"),
                },
                { child: "-" },
              ],
            ],
            hidden: MZ.Crawl(props.pData, "wdAvg", false) === false,
            sx: {
              borderTop: 2,
            },
          },
          // total WD
          {
            children: [
              [
                {
                  child: MZ.T("weekdays-total"),
                  rowSpan: 2,
                  colSpan: 2,
                },
                { child: MZ.T("current"), colSpan: 2 },
                { child: MZ.T("comparison"), colSpan: 2 },
                { child: MZ.T("chg"), colSpan: 2 },
                { child: MZ.T("index"), rowSpan: 2 },
                { child: MZ.T("chg"), rowSpan: 2 },
                { child: MZ.T("rank"), rowSpan: 2 },
                {
                  child: <MIcons.DeveloperBoard />,
                  rowSpan: 2,
                },
              ],
              [
                {
                  child: MZ.T(
                    props.portal === "corporate"
                      ? "properties"
                      : "outlets"
                  ),
                },
                { child: MZ.T("benchmark") },
                {
                  child: MZ.T(
                    props.portal === "corporate"
                      ? "properties"
                      : "outlets"
                  ),
                },
                { child: MZ.T("benchmark") },
                {
                  child: MZ.T(
                    props.portal === "corporate"
                      ? "properties"
                      : "outlets"
                  ),
                },
                { child: MZ.T("benchmark") },
              ],
            ],
            head: true,
            hidden: MZ.Crawl(props.pData, "wdTtl", false) === false,
          },
          {
            children: MZ.ForEach(
              MZ.Arr.sort(
                MZ.Arr.objToArr(MZ.Crawl(props.pData, "detailsWdTtl")),
                false,
                "order"
              ),
              (k, v) => {
                return [
                  {
                    child: `${MZ.T(
                      MZ.DateTime.weekdayNoText(v.num, false).toLowerCase()
                    )} ${MZ.T("total")}`,
                    colSpan: 2,
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(v, `actual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(v, `bActual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(v, `cActual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(v, `cbActual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(MZ.Crawl(v, `tChg`, "N/A"), 2),
                  },
                  {
                    child: MZ.Readable(MZ.Crawl(v, `bChg`, "N/A"), 2),
                  },
                  {
                    child: MZ.Readable(MZ.Crawl(v, `index`, "N/A"), 2),
                  },
                  {
                    child: MZ.Readable(MZ.Crawl(v, `chg`, "N/A"), 2),
                  },
                  {
                    child:
                      MZ.Crawl(v, `rank`, "N/A") +
                      " / " +
                      MZ.Crawl(v, `rankCount`, "N/A"),
                  },
                  { child: "-" },
                ];
              }
            ),
            hidden: MZ.Crawl(props.pData, "wdTtl", false) === false,
          },
          {
            children: [
              [
                {
                  child: MZ.T("total"),
                  colSpan: 2,
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/actual`, "N/A"),
                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/bActual`, "N/A"),
                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/cActual`, "N/A"),
                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/cbActual`, "N/A"),
                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/tChg`, "N/A"),
                    2
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/bChg`, "N/A"),
                    2
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/index`, "N/A"),
                    2
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/chg`, "N/A"),
                    2
                  ),
                },
                {
                  child:
                    MZ.Crawl(props.pData, `detailsTotal/rank`, "N/A") +
                    " / " +
                    MZ.Crawl(props.pData, `detailsTotal/rankCount`, "N/A"),
                },
                { child: "-" },
              ],
            ],
            hidden: MZ.Crawl(props.pData, "wdTtl", false) === false,
            sx: {
              borderTop: 2,
            },
          },
          // details
          {
            children: [
              [
                {
                  child: MZ.T("dates"),
                  rowSpan: 2,
                  colSpan: 2,
                },
                { child: MZ.T("current"), colSpan: 2 },
                { child: MZ.T("comparison"), colSpan: 2 },
                { child: MZ.T("chg"), colSpan: 2 },
                { child: MZ.T("index"), rowSpan: 2 },
                { child: MZ.T("chg"), rowSpan: 2 },
                { child: MZ.T("rank"), rowSpan: 2 },
                {
                  child: <MIcons.DeveloperBoard />,
                  rowSpan: 2,
                },
              ],
              [
                {
                  child: MZ.T(
                    props.portal === "corporate"
                      ? "properties"
                      : "outlets"
                  ),
                },
                { child: MZ.T("benchmark") },
                {
                  child: MZ.T(
                    props.portal === "corporate"
                      ? "properties"
                      : "outlets"
                  ),
                },
                { child: MZ.T("benchmark") },
                {
                  child: MZ.T(
                    props.portal === "corporate"
                      ? "properties"
                      : "outlets"
                  ),
                },
                { child: MZ.T("benchmark") },
              ],
            ],
            head: true,
          },
          {
            children: MZ.ForEach(
              MZ.Crawl(props.pData, "milestones"),
              (k, v) => {
                return [
                  {
                    child: v.date,
                  },
                  {
                    child: v.name,
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `details/${k}/actual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `details/${k}/bActual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `details/${k}/cActual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `details/${k}/cbActual`, "N/A"),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `details/${k}/tChg`, "N/A"),
                      2
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `details/${k}/bChg`, "N/A"),
                      2
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `details/${k}/index`, "N/A"),
                      2
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `details/${k}/chg`, "N/A"),
                      2
                    ),
                  },
                  {
                    child:
                      MZ.Crawl(props.pData, `details/${k}/rank`, "N/A") +
                      " / " +
                      MZ.Crawl(props.pData, `details/${k}/rankCount`, "N/A"),
                  },
                  {
                    child: (
                      <MUI.Button
                        sx={{ py: 0.5, px: 1.75, minWidth: 0 }}
                        disabled={
                          !(
                            MZ.Crawl(
                              props.pData,
                              `details/${k}/actionCount`,
                              "N/A"
                            ) > 0
                          )
                        }
                        onClick={() => {
                          setActionDialog({
                            m: v,
                            r: props.pData["round"] ?? 0,
                            t: MZ.Crawl(props.pData, `details/${k}`),
                            a: MZ.Crawl(
                              props.pData,
                              `details/${k}/actions`,
                              "N/A"
                            ),
                          });
                        }}
                      >
                        {MZ.Crawl(
                          props.pData,
                          `details/${k}/actionCount`,
                          "N/A"
                        )}
                      </MUI.Button>
                    ),
                  },
                ];
              }
            ),
          },
          {
            children: [
              [
                {
                  child: MZ.T("total"),
                  colSpan: 2,
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/actual`, "N/A"),

                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/bActual`, "N/A"),

                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/cActual`, "N/A"),

                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/cbActual`, "N/A"),

                    props.pData["round"] ?? 0
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/tChg`, "N/A"),
                    2
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/bChg`, "N/A"),
                    2
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/index`, "N/A"),
                    2
                  ),
                },
                {
                  child: MZ.Readable(
                    MZ.Crawl(props.pData, `detailsTotal/chg`, "N/A"),
                    2
                  ),
                },
                {
                  child:
                    MZ.Crawl(props.pData, `detailsTotal/rank`, "N/A") +
                    " / " +
                    MZ.Crawl(props.pData, `detailsTotal/rankCount`, "N/A"),
                },
                { child: "-" },
              ],
            ],
            hidden: MZ.Crawl(props.pData, "count", 0) <= 1,
            sx: {
              borderTop: 2,
            },
          },
        ]}
      />

      <MyActionTracker
        data={actionDialog}
        close={() => setActionDialog(null)}
        actions={MZ.Crawl(props.pData, "actions")}
      />
    </MUI.Box>
  );
};
export default MyDashboardDetailsTab;

//============< MyActionTracker
const MyActionTracker = (props) => {
  // const
  const RouterParams = Router.useParams();
  //
  return (
    <MyModal
      open={!!props.data}
      setOpen={() => props.close()}
      title={MZ.T("action-tracker")}
    >
      {props.data ? (
        <>
          <MyTable
            small={true}
            heads={[
              {
                children: [
                  [
                    {
                      child: MZ.T("dates"),
                      rowSpan: 2,
                      colSpan: 2,
                    },
                    { child: MZ.T("current"), colSpan: 2 },
                    { child: MZ.T("comparison"), colSpan: 2 },
                    { child: MZ.T("chg"), colSpan: 2 },
                    { child: MZ.T("index"), rowSpan: 2 },
                    { child: MZ.T("chg"), rowSpan: 2 },
                    { child: MZ.T("rank"), rowSpan: 2 },
                  ],
                  [
                    {
                      child: MZ.T(
                        props.portal === "corporate"
                          ? "properties"
                          : "outlets"
                      ),
                    },
                    { child: MZ.T("benchmark") },
                    {
                      child: MZ.T(
                        props.portal === "corporate"
                          ? "properties"
                          : "outlets"
                      ),
                    },
                    { child: MZ.T("benchmark") },
                    {
                      child: MZ.T(
                        props.portal === "corporate"
                          ? "properties"
                          : "outlets"
                      ),
                    },
                    { child: MZ.T("benchmark") },
                  ],
                ],
              },
            ]}
            bodies={[
              {
                children: [
                  [
                    {
                      child: props.data.m.date,
                    },
                    {
                      child: props.data.m.name,
                    },
                    {
                      child: MZ.Readable(props.data.t.actual, props.data.m.r),
                    },
                    {
                      child: MZ.Readable(props.data.t.bActual, props.data.m.r),
                    },
                    {
                      child: MZ.Readable(props.data.t.cActual, props.data.m.r),
                    },
                    {
                      child: MZ.Readable(props.data.t.cbActual, props.data.m.r),
                    },
                    {
                      child: MZ.Readable(props.data.t.tChg, 2),
                    },
                    {
                      child: MZ.Readable(props.data.t.bChg, 2),
                    },
                    {
                      child: MZ.Readable(props.data.t.index, 2),
                    },
                    {
                      child: MZ.Readable(props.data.t.chg, 2),
                    },
                    {
                      child: props.data.t.rank + " / " + props.data.t.rankCount,
                    },
                  ],
                ],
              },
            ]}
            sx={{ mb: 3 }}
          />
          <MyTable
            small={true}
            heads={[
              {
                children: [
                  [
                    { child: MZ.T("start-date") },
                    { child: MZ.T("end-date") },
                    { child: MZ.T("type") },
                    { child: MZ.T("description") },
                  ],
                ],
              },
            ]}
            bodies={[
              {
                children: MZ.ForEach(props.actions, (k, v) => {
                  if (!props.data.a.includes(v["id"])) return;
                  return [
                    {
                      child: new MZ.DateTime(v["startstamp"]).getFormated(
                        "Y-m-d"
                      ),
                    },
                    {
                      child: new MZ.DateTime(v["endstamp"]).getFormated(
                        "Y-m-d"
                      ),
                    },
                    {
                      child: v.type,
                    },
                    {
                      child: v.description,
                    },
                  ];
                }),
              },
            ]}
          />
        </>
      ) : null}
    </MyModal>
  );
};
