// packages
import React from "react";
import * as Router from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
// ui
import * as MUI from "@mui/material";
// data
import langData from "./data/languages.json";
// models
import * as MZ from "./models/MZ";
// pages
import MyDemoDashboard from "./pages/MyDemoDashboard";

MZ.Translation.addLocale("en", "English", false, langData.en);
MZ.Translation.addLocale("ar", "عربى", true, langData.ar);

function App() {
  // translation
  MZ.Translation.init("<?>");
  // const
  const RtlCache = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const LtrCache = createCache({ key: "muiltr" });

  // theme
  let colors = {
    p: new MZ.Color(26, 26, 26),
    s: new MZ.Color(102, 102, 102),
    l: new MZ.Color(238, 238, 238),
    w: new MZ.Color(255, 255, 255),
  };
  const MyTheme = MUI.createTheme({
    direction: MZ.Translation.localeDir,
    palette: {
      primary: {
        main: colors.p.getRGBA(),
        lighter: colors.p.setOpacity(0.9).getRGBA(),
        dark: colors.p.addBrightness(-0.5).getRGBA(),
        light: colors.p.setOpacity(0.25).getRGBA(),
        contrastText: colors.l.getRGBA(),
      },
      secondary: {
        main: colors.s.getRGBA(),
        lighter: colors.s.setOpacity(0.9).getRGBA(),
        dark: colors.s.addBrightness(-0.5).getRGBA(),
        light: colors.s.setOpacity(0.5).getRGBA(),
        contrastText: colors.w.getRGBA(),
      },
      light: {
        main: colors.l.getRGBA(),
        lighter: colors.l.setOpacity(0.9).getRGBA(),
        dark: colors.l.addBrightness(-0.5).getRGBA(),
        light: colors.l.setOpacity(0.3).getRGBA(),
        contrastText: colors.p.getRGBA(),
      },
      white: {
        main: colors.w.getRGBA(),
        lighter: colors.w.setOpacity(0.9).getRGBA(),
        dark: colors.w.addBrightness(0.5).getRGBA(),
        light: colors.w.setOpacity(0.5).getRGBA(),
        contrastText: colors.s.getRGBA(),
      },
    },
  });

  return (
    <CacheProvider value={MZ.IfRTL(RtlCache, LtrCache)}>
      <MUI.ThemeProvider theme={MyTheme}>
        <MUI.Box className="fill" dir={MZ.Translation.localeDir}>
          <Router.HashRouter>
            <Router.Routes>
              <Router.Route path="/" element={<MyDemoDashboard />} />
              <Router.Route
                path="/:portal/dashboard"
                element={<MyDemoDashboard />}
              />
              <Router.Route
                path="/:portal/dashboard/:outletId"
                element={<MyDemoDashboard />}
              />
              <Router.Route path="*" element={<div>404</div>} />
            </Router.Routes>
          </Router.HashRouter>
        </MUI.Box>
      </MUI.ThemeProvider>
    </CacheProvider>
  );
}

export default App;
