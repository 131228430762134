// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as ChJsUI from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
// components
import * as MZ from "../models/MZ";

// register some shit
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
// const
const ChartTypes = {
  bar: ({ options, data }) => (
    <ChJsUI.Bar options={options} data={data} height="100%" width="100%" />
  ),
  line: ({ options, data }) => (
    <ChJsUI.Line options={options} data={data} height="100%" width="100%" />
  ),
};

const MyChart = (props) => {
  return ChartTypes[props.type]({
    options: {
      fill: false,
      responsive: props.responsive ?? true,
      maintainAspectRatio: props.maintainAspectRatio ?? true,
      interaction: {
        intersect: false,
        mode: "point",
      },
      scales: {
        y: {
          //  beginAtZero: true,
          reverse: props.reverse ?? false,
          min: props.min,
          max: props.max,
          ticks: {
            stepSize: props.stepSize,
          },
        },
      },
      plugins: {
        title: {
          display: !!props.title,
          text: props.title,
        },
        legend: {
          display: props.displayDataLabels ?? true,
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          usePointStyle: true,
        },
      },
    },
    data: {
      labels: props.labels,
      datasets: MZ.ForEach(props.dataSets, (k, v) => {
        return {
          label: props.dataLabels[k] || k,
          data: v,
          color: MZ.Color.parse(props.dataColors[k] || "#333")
            .setOpacity(1)
            .getRGBA(),
          borderColor: MZ.Color.parse(props.dataColors[k] || "#333")
            .addBrightness(-0.5)
            .getRGBA(),
          backgroundColor: MZ.Color.parse(props.dataColors[k] || "#333")
            .addOpacity(-0.75)
            .getRGBA(),
          borderWidth: 1,
          fill: false,
        };
      }),
    },
  });
};
MyChart.propTypes = {
  type: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  dataLabels: PropTypes.array.isRequired,
  dataSets: PropTypes.array.isRequired,
  dataColors: PropTypes.array.isRequired,
  title: PropTypes.string,
  maintainAspectRatio: PropTypes.bool,
  responsive: PropTypes.bool,
  reverse: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  stepSize: PropTypes.number,
  displayDataLabels: PropTypes.bool,
};
export default MyChart;
