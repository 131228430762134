// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import * as MZ from "../../models/MZ";
// components
import MyFormDialog from "../../components/MyFormDialog";

//============< filterForm
const MyDashboardFilterForm = (props) => {
  // const
  // form
  var formController = MZ.FormController();
  formController.import(props.filterExport);
  // ass
  const [oExp, setOExp] = React.useState(0);
  // use
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     props.setFilterExport(formController.export());
  //     props.setFilterData(formController.toObj());
  //   }, 1);
  // }, []);
  //
  let now = new MZ.DateTime();
  return (
    <MyFormDialog
      open={props.open}
      title={MZ.T("filter")}
      onSubmit={(form) => {
        props.setLoading(true);
        props.setFilterExport(formController.export());
        props.setFilterData(formController.toObj());
        props.setOpen(false);
      }}
      maxWidth="md"
      actions={[
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Close />}
          loading={false}
          loadingPosition="start"
          type="cancel"
          variant="contained"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          {MZ.T("cancel")}
        </MLab.LoadingButton>,
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Send />}
          loading={props.loading}
          loadingPosition="start"
          type="submit"
          variant="contained"
        >
          {MZ.T("go")}
        </MLab.LoadingButton>,
      ]}
    >
      {MZ.If(
        !!props.apiError,
        <MUI.Alert severity="error" sx={{ m: 3, mt: 0 }}>
          {props.apiError}
        </MUI.Alert>
      )}
      <MUI.Grid
        container
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ mb: 3 }}
      >
        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="start_date"
            initValue={now.addDays(-1).getFormated("Y-m-d")}
            formController={formController}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  className="w100"
                  label={MZ.T("start-date")}
                  name="start_date"
                  type="date"
                  value={value}
                  onChange={(evt) => setValue(evt.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="end_date"
            initValue={now.addDays(-1).getFormated("Y-m-d")}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("end-date")}
                name="end_date"
                type="date"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="old_date"
            initValue={now.addDays(-1).addYears(-1).getFormated("Y-m-d")}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("comparison-start-date")}
                name="old_date"
                type="date"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Divider sx={{ my: 1, backgroundColor: "primary.main" }} />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Stack
            direction="row"
            justifyContent="space-evenly"
            flexWrap="wrap"
          >
            {MZ.ForEach(
              [
                {
                  name: MZ.T("yesterday"),
                  onClick: () => {
                    formController.setValue(
                      "start_date",
                      null,
                      now.addDays(-1).getFormated("Y-m-d")
                    );
                    formController.setValue(
                      "end_date",
                      null,
                      now.addDays(-1).getFormated("Y-m-d")
                    );
                    formController.setValue(
                      "old_date",
                      null,
                      now.addDays(-1).addYears(-1).getFormated("Y-m-d")
                    );
                  },
                },
                {
                  name: MZ.T("week-to-date"),
                  onClick: () => {
                    formController.setValue(
                      "start_date",
                      null,
                      now.weekFirstDay().getFormated("Y-m-d")
                    );
                    formController.setValue(
                      "end_date",
                      null,
                      now.addDays(-1).getFormated("Y-m-d")
                    );
                    formController.setValue(
                      "old_date",
                      null,
                      now.weekFirstDay().addYears(-1).getFormated("Y-m-d")
                    );
                  },
                },
                {
                  name: MZ.T("month-to-date"),
                  onClick: () => {
                    formController.setValue(
                      "start_date",
                      null,
                      now.setDay(1).getFormated("Y-m-d")
                    );
                    formController.setValue(
                      "end_date",
                      null,
                      now.addDays(-1).getFormated("Y-m-d")
                    );
                    formController.setValue(
                      "old_date",
                      null,
                      now.setDay(1).addYears(-1).getFormated("Y-m-d")
                    );
                  },
                },
                {
                  name: MZ.T("last-month"),
                  onClick: () => {
                    formController.setValue(
                      "start_date",
                      null,
                      now.addMonths(-1).setDay(1).getFormated("Y-m-d")
                    );
                    formController.setValue(
                      "end_date",
                      null,
                      now.setDay(0).getFormated("Y-m-d")
                    );
                    formController.setValue(
                      "old_date",
                      null,
                      now
                        .addMonths(-1)
                        .setDay(1)
                        .addYears(-1)
                        .getFormated("Y-m-d")
                    );
                  },
                },
                {
                  name: MZ.T("year-to-date"),
                  onClick: () => {
                    formController.setValue(
                      "start_date",
                      null,
                      now.setMonth(1).setDay(1).getFormated("Y-m-d")
                    );
                    formController.setValue(
                      "end_date",
                      null,
                      now.addDays(-1).getFormated("Y-m-d")
                    );
                    formController.setValue(
                      "old_date",
                      null,
                      now
                        .setMonth(1)
                        .setDay(1)
                        .addYears(-1)
                        .getFormated("Y-m-d")
                    );
                  },
                },
              ],
              (k, v) => (
                <MUI.Button
                  key={`filterPresets-${k}`}
                  variant="outlined"
                  onClick={v.onClick}
                  sx={{ width: "auto", mb: 1, mx: 0.5 }}
                >
                  {v.name}
                </MUI.Button>
              )
            )}
          </MUI.Stack>
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Divider sx={{ my: 1, backgroundColor: "primary.main" }} />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="milestone"
            initValue="daily"
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.FormControl fullWidth>
                <MUI.InputLabel id="filter-milestone">
                  {MZ.T("milestone")}
                </MUI.InputLabel>
                <MUI.Select
                  labelId="filter-milestone"
                  className="w100"
                  label={MZ.T("milestone")}
                  name="milestone"
                  value={value}
                  onChange={(evt) => setValue(evt.target.value)}
                >
                  <MUI.MenuItem value="daily">{MZ.T("daily")}</MUI.MenuItem>
                  <MUI.MenuItem value="weekly">{MZ.T("weekly")}</MUI.MenuItem>
                  <MUI.MenuItem value="monthly">{MZ.T("monthly")}</MUI.MenuItem>
                </MUI.Select>
              </MUI.FormControl>
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="first_weekday"
            initValue={0}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.FormControl fullWidth>
                <MUI.InputLabel id="filter-fdw">
                  {MZ.T("first-weekday")}
                </MUI.InputLabel>
                <MUI.Select
                  labelId="filter-fdw"
                  className="w100"
                  label={MZ.T("first-weekday")}
                  name="first_weekday"
                  value={value}
                  onChange={(evt) => setValue(evt.target.value)}
                >
                  <MUI.MenuItem value={0}>{MZ.T("sunday")}</MUI.MenuItem>
                  <MUI.MenuItem value={1}>{MZ.T("monday")}</MUI.MenuItem>
                  <MUI.MenuItem value={2}>{MZ.T("tuesday")}</MUI.MenuItem>
                  <MUI.MenuItem value={3}>{MZ.T("wednesday")}</MUI.MenuItem>
                  <MUI.MenuItem value={4}>{MZ.T("thursday")}</MUI.MenuItem>
                  <MUI.MenuItem value={5}>{MZ.T("friday")}</MUI.MenuItem>
                  <MUI.MenuItem value={6}>{MZ.T("saturday")}</MUI.MenuItem>
                </MUI.Select>
              </MUI.FormControl>
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="currency"
            formController={formController}
            initValue="usd"
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                select
                label={MZ.T("currency")}
                name="currency"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
              >
                <MUI.MenuItem value="usd">
                  {MZ.T("default-currency")}
                </MUI.MenuItem>
                {!props.dependants
                  ? null
                  : MZ.ForEach(props.dependants["currencies"], (k, v) => (
                      <MUI.MenuItem
                        key={`FCO-${k}`}
                        value={v.id}
                        children={v.name}
                      />
                    ))}
              </MUI.TextField>
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Divider sx={{ my: 1, backgroundColor: "primary.main" }} />
        </MUI.Grid>
        {props.portal === "outlet" ? null : (
          <MUI.Grid item xs={12}>
            <MZ.InputControllerWidget
              name="concern"
              initValue={1}
              formController={formController}
              inputField={(value, setValue, error) => (
                <MUI.TextField
                  className="w100"
                  label={MZ.T("concern-neg-perc")}
                  name="concern"
                  type="number"
                  value={value}
                  onChange={(evt) => setValue(evt.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </MUI.Grid>
        )}
        {props.portal === "outlet" ? null : (
          <MUI.Grid item xs={12}>
            <MUI.Divider sx={{ my: 1, backgroundColor: "primary.main" }} />
          </MUI.Grid>
        )}
        <MUI.Grid item xs={12}>
          <MUI.Stack
            direction="row"
            justifyContent="space-evenly"
            flexWrap="wrap"
          >
            <MZ.InputControllerWidget
              name="extras"
              index={0}
              initValue={false}
              staticValue="avgWeekdays"
              formController={formController}
              inputField={(value, setValue, error) => (
                <MUI.FormControlLabel
                  control={
                    <MUI.Switch
                      checked={value}
                      onChange={(evt) => setValue(evt.target.checked)}
                    />
                  }
                  label={MZ.T("weekdays-average")}
                />
              )}
            />
            <MZ.InputControllerWidget
              name="extras"
              index={1}
              initValue={false}
              staticValue="totalWeekdays"
              formController={formController}
              inputField={(value, setValue, error) => (
                <MUI.FormControlLabel
                  control={
                    <MUI.Switch
                      checked={value}
                      onChange={(evt) => setValue(evt.target.checked)}
                    />
                  }
                  label={MZ.T("weekdays-total")}
                />
              )}
            />
            <MZ.InputControllerWidget
              name="extras"
              index={2}
              initValue={false}
              staticValue="compareWeekdays"
              formController={formController}
              inputField={(value, setValue, error) => (
                <MUI.FormControlLabel
                  control={
                    <MUI.Switch
                      checked={value}
                      onChange={(evt) => setValue(evt.target.checked)}
                    />
                  }
                  label={MZ.T("compare-weekdays")}
                />
              )}
            />
          </MUI.Stack>
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Divider sx={{ my: 1, backgroundColor: "primary.main" }} />
        </MUI.Grid>

        {props.portal === "outlet" ? null : (
          <MUI.Grid item xs={12}>
            <MZ.GroupControllerWidget
              formController={formController}
              name="outlets"
              inputField={(values) => {
                let checks = MZ.CountIf(values, (k, v) => v === true);
                let count = Object.keys(values).length;
                return (
                  <MUI.FormControlLabel
                    label={MZ.T(
                      props.portal === "corporate"
                        ? "properties"
                        : "outlets"
                    )}
                    control={
                      <MUI.Checkbox
                        checked={checks === count}
                        indeterminate={checks > 0 && checks < count}
                        onChange={(evt) =>
                          formController.setValues(
                            "outlets",
                            evt.target.checked
                          )
                        }
                      />
                    }
                  />
                );
              }}
            />
            {props.dependants
              ? MZ.ForEach(props.dependants.outlets, (k, v) => {
                  let key = `outletFilter-${k}`;
                  return (
                    <MUI.Accordion key={key} expanded={oExp === k}>
                      <MUI.AccordionSummary
                        expandIcon={
                          <MIcons.ExpandMore
                            onClick={() => setOExp(oExp === k ? null : k)}
                            sx={{
                              pointerEvents: "auto",
                            }}
                          />
                        }
                        sx={{
                          pointerEvents: "none",
                        }}
                      >
                        <MZ.InputControllerWidget
                          formController={formController}
                          name="outlets"
                          index={parseInt(k)}
                          initValue={true}
                          staticValue={v.id}
                          inputField={(value, setValue, error) => (
                            <MUI.FormControlLabel
                              sx={{
                                pointerEvents: "auto",
                              }}
                              label={v.name}
                              control={
                                <MUI.Checkbox
                                  checked={value}
                                  onChange={(evt) =>
                                    setValue(evt.target.checked)
                                  }
                                />
                              }
                            />
                          )}
                        />
                      </MUI.AccordionSummary>
                      <MUI.AccordionDetails sx={{ pl: 3 }}>
                        <MZ.GroupControllerWidget
                          formController={formController}
                          name={`benchmarks-${v.id}`}
                          inputField={(values) => {
                            let checks = MZ.CountIf(
                              values,
                              (k, v) => v === true
                            );
                            let count = Object.keys(values).length;
                            return (
                              <MUI.FormControlLabel
                                label={MZ.T("benchmarks")}
                                control={
                                  <MUI.Checkbox
                                    checked={checks === count}
                                    indeterminate={checks > 0 && checks < count}
                                    onChange={(evt) =>
                                      formController.setValues(
                                        `benchmarks-${v.id}`,
                                        evt.target.checked
                                      )
                                    }
                                  />
                                }
                              />
                            );
                          }}
                        />
                        <MUI.Stack
                          direction="row"
                          justifyContent="flex-start"
                          flexWrap="wrap"
                          sx={{ pl: 3 }}
                        >
                          {MZ.ForEach(props.dependants.outlets, (k2, v2) => {
                            if (k === k2) return;
                            return (
                              <MZ.InputControllerWidget
                                key={`${key}-${k2}`}
                                formController={formController}
                                name={`benchmarks-${v.id}`}
                                index={parseInt(k2)}
                                initValue={true}
                                staticValue={v2.id}
                                inputField={(value, setValue, error) => (
                                  <MUI.FormControlLabel
                                    label={v2.name}
                                    control={
                                      <MUI.Checkbox
                                        checked={value}
                                        onChange={(evt) =>
                                          setValue(evt.target.checked)
                                        }
                                      />
                                    }
                                  />
                                )}
                              />
                            );
                          })}
                        </MUI.Stack>
                        <MZ.InputControllerWidget
                          formController={formController}
                          name="outBenchs"
                          index={parseInt(k)}
                          initValue={true}
                          staticValue={v.id}
                          inputField={(value, setValue, error) => (
                            <MUI.FormControlLabel
                              label={MZ.T("other-bechmarks")}
                              control={
                                <MUI.Checkbox
                                  checked={value}
                                  onChange={(evt) =>
                                    setValue(evt.target.checked)
                                  }
                                />
                              }
                            />
                          )}
                        />
                      </MUI.AccordionDetails>
                    </MUI.Accordion>
                  );
                })
              : null}
          </MUI.Grid>
        )}

        {props.portal === "outlet" ? null : (
          <MUI.Grid item xs={12}>
            <MUI.Divider sx={{ my: 1, backgroundColor: "primary.main" }} />
          </MUI.Grid>
        )}
        <MUI.Grid item xs={12}>
          <MZ.GroupControllerWidget
            formController={formController}
            name="weekdays"
            inputField={(values) => {
              let checks = MZ.CountIf(values, (k, v) => v === true);
              let count = Object.keys(values).length;
              return (
                <MUI.FormControlLabel
                  label={MZ.T("weekdays")}
                  control={
                    <MUI.Checkbox
                      checked={checks === count}
                      indeterminate={checks > 0 && checks < count}
                      onChange={(evt) =>
                        formController.setValues("weekdays", evt.target.checked)
                      }
                    />
                  }
                />
              );
            }}
          />
          <MUI.Stack
            direction="row"
            justifyContent="flex-start"
            flexWrap="wrap"
            sx={{ pl: 3 }}
          >
            {MZ.ForEach(
              [
                MZ.T("sunday"),
                MZ.T("monday"),
                MZ.T("tuesday"),
                MZ.T("wednesday"),
                MZ.T("thursday"),
                MZ.T("friday"),
                MZ.T("saturday"),
              ],
              (k, v) => (
                <MZ.InputControllerWidget
                  key={`filterWeekdays-${k}`}
                  formController={formController}
                  name="weekdays"
                  index={parseInt(k)}
                  initValue={true}
                  staticValue={k}
                  inputField={(value, setValue, error) => (
                    <MUI.FormControlLabel
                      label={v}
                      control={
                        <MUI.Checkbox
                          checked={value}
                          onChange={(evt) => setValue(evt.target.checked)}
                        />
                      }
                    />
                  )}
                />
              )
            )}
          </MUI.Stack>
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Divider sx={{ my: 1, backgroundColor: "primary.main" }} />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MZ.GroupControllerWidget
            formController={formController}
            name="monthdays"
            inputField={(values) => {
              let checks = MZ.CountIf(values, (k, v) => v === true);
              let count = Object.keys(values).length;
              return (
                <MUI.FormControlLabel
                  label={MZ.T("days-of-month")}
                  control={
                    <MUI.Checkbox
                      checked={checks === count}
                      indeterminate={checks > 0 && checks < count}
                      onChange={(evt) =>
                        formController.setValues(
                          "monthdays",
                          evt.target.checked
                        )
                      }
                    />
                  }
                />
              );
            }}
          />
          <MUI.Stack
            direction="row"
            justifyContent="flex-start"
            flexWrap="wrap"
            sx={{ pl: 3 }}
          >
            {MZ.Generate(31, (i) => (
              <MZ.InputControllerWidget
                key={`filter-monthdays-${i}`}
                formController={formController}
                name="monthdays"
                index={i}
                initValue={true}
                staticValue={`${i + 1}`}
                inputField={(value, setValue, error) => (
                  <MUI.FormControlLabel
                    label={`${i + 1}`}
                    control={
                      <MUI.Checkbox
                        checked={value}
                        onChange={(evt) => setValue(evt.target.checked)}
                      />
                    }
                  />
                )}
              />
            ))}
          </MUI.Stack>
        </MUI.Grid>
      </MUI.Grid>
    </MyFormDialog>
  );
};
export default MyDashboardFilterForm;
