// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../models/MyAppM";
import * as MZ from "../models/MZ";
// layouts
import MyTranslationSelect from "./MyTranslationSelect";

const MyPage = (props) => {
  const RouterNavigate = Router.useNavigate();
  // const RouterLocation = Router.useLocation();
  // const RouterParams = Router.useParams();
  // check if logged in
  return (
    <MUI.Box
      className="fill"
      display="grid"
      gridTemplateColumns="1fr"
      gridTemplateRows="4rem 1fr"
      gap={0}
    >
      <MUI.Box gridColumn="1" gridRow="1" sx={{ overflow: "hidden" }}>
        <MyTopMenu outlet={props.outlet ?? {}} />
      </MUI.Box>
      <MUI.Box gridColumn="1" gridRow="2" sx={{ overflow: "auto", pb: 25 }}>
        <MUI.Box>{props.children}</MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
};

export default MyPage;

//============< Top menu
const MyTopMenu = (props) => {
  const shortMenu = MUI.useMediaQuery("(max-width:600px)");

  return (
    <MUI.AppBar position="static" color="white" elevation={0} className="h100">
      <MUI.Toolbar
        className="fill"
        sx={{
          justifyContent: "start",
          alignItems: "center",
          flexGrow: 1,
          borderBottom: 1,
          borderColor: "divider",
        }}
        disableGutters={shortMenu}
      >
        <MUI.Typography
          sx={{ px: 1, py: 2, marginInlineEnd: 1 }}
          className="h100"
        >
          <img
            src={MZ.If(
              !!props.outlet.logo,
              MyAppM.getMediaUrl(props.outlet.logo),
              "./media/logo.png"
            )}
            alt="logo"
            height="100%"
          />
        </MUI.Typography>

        <MUI.Typography variant="h6" sx={{ flexGrow: 1 }}>
          {props.outlet.name}
        </MUI.Typography>

        <MyTranslationSelect
          variant="standard"
          size="small"
          showIcon={!shortMenu}
          short={shortMenu}
        />
      </MUI.Toolbar>
    </MUI.AppBar>
  );
};
