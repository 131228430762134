// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
//==============================
const MyFormDialog = (props) => {
  return (
    <MUI.Dialog
      component="form"
      onSubmit={(evt) => {
        evt.preventDefault();
        if (props.onSubmit) props.onSubmit(evt.target);
      }}
      onClose={props.onClose}
      noValidate
      open={props.open}
      maxWidth={props.maxWidth}
      TransitionComponent={props.TransitionComponent}
      keepMounted={!!props.TransitionComponent}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
      sx={{
        ".MuiDialogTitle-root+.MuiDialogContent-root": {
          py: 3,
          px: 2.5,
        },
      }}
    >
      <MUI.DialogTitle
        id="form-dialog-title"
        sx={{ borderBottom: 0.25, borderColor: "primary.light" }}
      >
        {props.title}
      </MUI.DialogTitle>
      <MUI.DialogContent>{props.children}</MUI.DialogContent>
      <MUI.DialogActions sx={{ borderTop: 0.25, borderColor: "primary.light" }}>
        {props.actions}
      </MUI.DialogActions>
    </MUI.Dialog>
  );
};
MyFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.array.isRequired,
  maxWidth: PropTypes.string.isRequired,
  TransitionComponent: PropTypes.object,
};
export default MyFormDialog;
