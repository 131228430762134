// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
import * as MIcons from "@mui/icons-material";
//==============================
const MyModal = (props) => {
  return (
    <MUI.Dialog
      open={props.open}
      TransitionComponent={props.TransitionComponent}
      keepMounted={!!props.TransitionComponent}
      fullScreen={true}
      sx={{
        ".MuiDialogContent-root": {
          py: props.py ?? 2,
          px: props.px ?? 2,
          overflowY: props.scroll === false ? "hidden" : "auto",
        },
        ".MuiDialogTitle-root+.MuiDialogContent-root": {
          py: props.py ?? 2,
          px: props.px ?? 2,
          overflowY: props.scroll === false ? "hidden" : "auto",
        },
        ".MuiDialog-paper": {
          borderRadius: 2,
        },
        margin: props.m ?? 2.5,
        ...props.sx,
      }}
    >
      {!props.title ? null : <MUI.DialogTitle>{props.title}</MUI.DialogTitle>}
      <MUI.DialogContent sx={{ height: "100%" }}>
        {props.children}
      </MUI.DialogContent>

      <MUI.IconButton
        sx={{ position: "absolute", right: 0 }}
        color="primary"
        onClick={() => props.setOpen(false)}
      >
        <MIcons.Close />
      </MUI.IconButton>
    </MUI.Dialog>
  );
};
MyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  TransitionComponent: PropTypes.object,
};
export default MyModal;
