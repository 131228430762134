// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
// models
import * as MZ from "../../models/MZ";
// layouts
// components
import MyTable from "../../components/MyTable";
import MyChart from "../../components/MyChart";
import MyZoomDialog from "../../components/MyZoomDialog";
import MyModal from "../../components/MyModal";

//============< Performance
const MyDashboardPerformanceTab = (props) => {
  // const
  // const RouterNavigate = Router.useNavigate();
  //
  const [underPerf, setUnderPerf] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  return (
    <MUI.Box>
      <MUI.Grid
        container
        direction="row"
        spacing={0.5}
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ mb: 3 }}
      >
        <MUI.Grid item xs={12} md={props.portal === "outlet" ? 12 : 6}>
          <MUI.Box>
            <MUI.Stack
              className="fill"
              direction="column"
              spacing={0.5}
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <MUI.Paper
                variant="outlined"
                square
                className="fill"
                sx={{ px: 2, py: 1.5 }}
              >
                <MUI.Stack
                  className="fill"
                  direction="row"
                  justifyContent="space-between"
                >
                  <MUI.Typography variant="h6">
                    {MZ.T("total-actuals")}
                  </MUI.Typography>

                  <MUI.Typography variant="h6">
                    {MZ.If(
                      props.loading,
                      <MUI.Skeleton variant="text" width={100} height="100%" />,
                      MZ.Readable(
                        MZ.Crawl(props.pData, "overall/actual", "N/A"),
                        props.pData["round"] ?? 0
                      )
                    )}
                  </MUI.Typography>
                </MUI.Stack>
                <MUI.Stack
                  className="fill"
                  direction="row"
                  justifyContent="space-between"
                >
                  <MUI.Typography variant="subtitle1">
                    {MZ.T("comparison")}
                  </MUI.Typography>

                  <MUI.Typography
                    variant="subtitle1"
                    color={
                      MZ.Crawl(props.pData, "overall/cVariance") > 0
                        ? "green"
                        : "red"
                    }
                  >
                    {MZ.If(
                      props.loading,
                      <MUI.Skeleton variant="text" width={100} height="100%" />,
                      MZ.Readable(
                        MZ.Crawl(props.pData, "overall/cActual", "N/A"),
                        props.pData["round"] ?? 0
                      )
                    )}
                  </MUI.Typography>
                </MUI.Stack>
              </MUI.Paper>
              <MUI.Paper
                variant="outlined"
                square
                className="fill"
                sx={{ px: 2, py: 1.5 }}
              >
                <MUI.Stack
                  className="fill"
                  direction="row"
                  justifyContent="space-between"
                >
                  <MUI.Typography variant="h6">
                    {MZ.T("overall-budget")}
                  </MUI.Typography>

                  <MUI.Typography
                    variant="h6"
                    color={
                      MZ.Crawl(props.pData, "overall/bVariance") > 0
                        ? "green"
                        : "red"
                    }
                  >
                    {MZ.If(
                      props.loading,
                      <MUI.Skeleton variant="text" width={100} height="100%" />,
                      MZ.Readable(
                        MZ.Crawl(props.pData, "overall/bActual", "N/A"),

                        props.pData["round"] ?? 0
                      )
                    )}
                  </MUI.Typography>
                </MUI.Stack>
                <MUI.Stack
                  className="fill"
                  direction="row"
                  justifyContent="space-between"
                >
                  <MUI.Typography variant="subtitle1">
                    {MZ.T("variance")}
                  </MUI.Typography>

                  <MUI.Typography
                    variant="subtitle1"
                    color={
                      MZ.Crawl(props.pData, "overall/bVariance") > 0
                        ? "green"
                        : "red"
                    }
                  >
                    {MZ.If(
                      props.loading,
                      <MUI.Skeleton variant="text" width={100} height="100%" />,
                      MZ.Readable(
                        MZ.Crawl(props.pData, "overall/bVariance", "N/A"),

                        props.pData["round"] ?? 0
                      )
                    )}
                  </MUI.Typography>
                </MUI.Stack>
              </MUI.Paper>
              <MUI.Paper
                variant="outlined"
                square
                className="fill"
                sx={{ px: 2, py: 1.5 }}
              >
                <MUI.Stack
                  className="fill"
                  direction="row"
                  justifyContent="space-between"
                >
                  <MUI.Typography variant="h6">
                    {MZ.T("overall-forecast")}
                  </MUI.Typography>

                  <MUI.Typography
                    variant="h6"
                    color={
                      MZ.Crawl(props.pData, "overall/fVariance") > 0
                        ? "green"
                        : "red"
                    }
                  >
                    {MZ.If(
                      props.loading,
                      <MUI.Skeleton variant="text" width={100} height="100%" />,
                      MZ.Readable(
                        MZ.Crawl(props.pData, "overall/fActual", "N/A"),

                        props.pData["round"] ?? 0
                      )
                    )}
                  </MUI.Typography>
                </MUI.Stack>
                <MUI.Stack
                  className="fill"
                  direction="row"
                  justifyContent="space-between"
                >
                  <MUI.Typography variant="subtitle1">
                    {MZ.T("variance")}
                  </MUI.Typography>

                  <MUI.Typography
                    variant="subtitle1"
                    color={
                      MZ.Crawl(props.pData, "overall/fVariance") > 0
                        ? "green"
                        : "red"
                    }
                  >
                    {MZ.If(
                      props.loading,
                      <MUI.Skeleton variant="text" width={100} height="100%" />,
                      MZ.Readable(
                        MZ.Crawl(props.pData, "overall/fVariance", "N/A"),

                        props.pData["round"] ?? 0
                      )
                    )}
                  </MUI.Typography>
                </MUI.Stack>
              </MUI.Paper>
              {props.portal === "outlet" ? null : (
                <MUI.Paper
                  variant="outlined"
                  square
                  className="fill"
                  sx={{ px: 2, py: 1.5 }}
                >
                  <MUI.Stack
                    className="fill"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <MUI.Typography variant="h6">
                      {MZ.T("areas-of-concern")}
                    </MUI.Typography>

                    <MUI.Typography variant="h6">
                      {MZ.If(
                        props.loading,
                        <MUI.Skeleton
                          variant="text"
                          width={100}
                          height="100%"
                        />,
                        <MUI.FormControlLabel
                          labelPlacement="start"
                          label={MZ.Crawl(props.pData, "areasOfConcern", "N/A")}
                          control={
                            <MUI.Switch
                              value={underPerf}
                              onChange={(evt) => setUnderPerf(!underPerf)}
                            />
                          }
                        />
                      )}
                    </MUI.Typography>
                  </MUI.Stack>
                </MUI.Paper>
              )}
            </MUI.Stack>
          </MUI.Box>
        </MUI.Grid>
        <MUI.Grid item xs={12} md={props.portal === "outlet" ? 12 : 6}>
          <MUI.Paper
            className="fill"
            variant="outlined"
            sx={{ minHeight: 300 }}
            square
          >
            {MZ.If(
              props.loading,
              <MUI.Skeleton height="100%" width="100%" variant="rectangular" />,
              <MyZoomDialog>
                <MyChart
                  type={MZ.Crawl(props.pData, "count", 0) > 1 ? "line" : "bar"}
                  labels={MZ.ForEach(
                    MZ.Crawl(props.pData, "milestones"),
                    (k, v) => {
                      return v.date;
                    }
                  )}
                  dataSets={[
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(
                        props.pData,
                        `overallGraph/${k}/actual`,
                        NaN
                      );
                    }),
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(
                        props.pData,
                        `overallGraph/${k}/cActual`,
                        NaN
                      );
                    }),
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(
                        props.pData,
                        `overallGraph/${k}/bActual`,
                        NaN
                      );
                    }),
                    MZ.ForEach(MZ.Crawl(props.pData, "milestones"), (k, v) => {
                      return MZ.Crawl(
                        props.pData,
                        `overallGraph/${k}/fActual`,
                        NaN
                      );
                    }),
                  ]}
                  dataLabels={[
                    MZ.T("actuals"),
                    MZ.T("comparison"),
                    MZ.T("budget"),
                    MZ.T("forecast"),
                  ]}
                  dataColors={[
                    new MZ.Color(0, 204, 0),
                    new MZ.Color(204, 0, 0),
                    new MZ.Color(204, 204, 0),
                    new MZ.Color(0, 204, 204),
                  ]}
                  maintainAspectRatio={false}
                />
              </MyZoomDialog>
            )}
          </MUI.Paper>
        </MUI.Grid>
      </MUI.Grid>
      {props.portal === "outlet" ? null : (
        <MyTable
          small={true}
          bodyLoading={props.loading}
          bodyLoadingColSpan={8}
          heads={[
            {
              children: [
                [
                  {
                    child: MZ.T(
                      props.portal === "corporate" ? "properties" : "outlets"
                    ),
                  },
                  { child: MZ.T("actuals") },
                  { child: MZ.T("budget") },
                  { child: MZ.T("variance") },
                  { child: MZ.T("forecast") },
                  { child: MZ.T("variance") },
                  { child: MZ.T("comparison") },
                  { child: MZ.T("variance") },
                ],
              ],
            },
          ]}
          bodies={[
            {
              children: MZ.ForEach(MZ.Crawl(props.pData, "outlets"), (k, v) => {
                if (
                  underPerf === true &&
                  MZ.Crawl(
                    props.pData,
                    `overallOutlets/${k}/areasOfConcern`
                  ) === 0
                )
                  return undefined;

                return [
                  {
                    child: (
                      <Router.Link
                        to={`/${
                          props.portal === "corporate" ? "property" : "outlet"
                        }/dashboard/${encodeURI(window.btoa(v.id))}`}
                        target="_blank"
                      >
                        {v.name}
                      </Router.Link>
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `overallOutlets/${k}/actual`),
                      props.pData["round"] ?? 0
                    ),
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `overallOutlets/${k}/bActual`),
                      props.pData["round"] ?? 0
                    ),
                    sx: {
                      color:
                        MZ.Crawl(props.pData, `overallOutlets/${k}/bVariance`) >
                        0
                          ? "green"
                          : "red",
                    },
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `overallOutlets/${k}/bVariance`),
                      props.pData["round"] ?? 0
                    ),
                    sx: {
                      color:
                        MZ.Crawl(props.pData, `overallOutlets/${k}/bVariance`) >
                        0
                          ? "green"
                          : "red",
                    },
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `overallOutlets/${k}/fActual`),
                      props.pData["round"] ?? 0
                    ),
                    sx: {
                      color:
                        MZ.Crawl(props.pData, `overallOutlets/${k}/fVariance`) >
                        0
                          ? "green"
                          : "red",
                    },
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `overallOutlets/${k}/fVariance`),
                      props.pData["round"] ?? 0
                    ),
                    sx: {
                      color:
                        MZ.Crawl(props.pData, `overallOutlets/${k}/fVariance`) >
                        0
                          ? "green"
                          : "red",
                    },
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `overallOutlets/${k}/cActual`),
                      props.pData["round"] ?? 0
                    ),
                    sx: {
                      color:
                        MZ.Crawl(props.pData, `overallOutlets/${k}/cVariance`) >
                        0
                          ? "green"
                          : "red",
                    },
                  },
                  {
                    child: MZ.Readable(
                      MZ.Crawl(props.pData, `overallOutlets/${k}/cVariance`),
                      props.pData["round"] ?? 0
                    ),
                    sx: {
                      color:
                        MZ.Crawl(props.pData, `overallOutlets/${k}/cVariance`) >
                        0
                          ? "green"
                          : "red",
                    },
                  },
                ];
              }),
            },
          ]}
        />
      )}
    </MUI.Box>
  );
};
export default MyDashboardPerformanceTab;
